<template>
  <div id="nav" class="padding-2-no-print-vertical" :style="navBarColor()">
    <div class="flex-row">
      <router-link to="/search" class="no-link-style flex-row">
        <img class="icon" style="margin-right: 10px" src="/assets/logo-yellow.svg" alt="iAppraise" />
        <span class="h4 vertical-flex-center">iAppraise v2</span>
      </router-link>
      <h6 class="h4 no-print vertical-flex-center" style="margin-left: 10px" v-if="currentDealer">-</h6>
      <h6 class="h4 no-print vertical-flex-center" style="margin-left: 10px" v-if="currentDealer">
        {{ currentDealer.first_name }} {{ currentDealer.last_name }}
      </h6>
      <div class="margin-left-auto margin-right vertical-flex-center flex-row no-print">
        <input type="date" class="date-inputs" v-model="searchStartDate" />
        <p class="margin-left-05 margin-right-05 vertical-flex-center">-</p>
        <input type="date" class="date-inputs" v-model="searchEndDate" />
      </div>
      <div v-if="currentDealer && currentDealer.is_manager" class="spacer no-print"></div>
      <div
        v-if="currentDealer && currentDealer.is_manager"
        class="vertical-flex-center margin-left no-print"
        style="position: relative"
      >
        <div id="account-div" class="flex-column">
          <img src="/assets/img/icon-graph-v2.svg" alt="" class="btn-icon horizontal-flex-center" />
          <p class="body-2 horizontal-flex-center">Reports</p>
        </div>
        <div class="account-container" :style="{ backgroundColor: navBarColor() }">
          <button
            class="drop-down-button no-mobile"
            :style="{ backgroundColor: navBarColor() }"
            v-on:click="goToTables()"
          >
            Tables
          </button>
          <button class="drop-down-button no-mobile" v-if="currentDealer" v-on:click="goToReports()">Analytics</button>
          <button class="drop-down-button no-mobile" v-if="currentDealer" v-on:click="goToPreBuilts()">
            Downloads
          </button>
        </div>
      </div>
      <div class="spacer no-print margin-left"></div>
      <div class="vertical-flex-center margin-left no-print" style="position: relative">
        <div id="account-div" class="flex-column">
          <img src="/assets/img/icon-profile.png" alt="" class="btn-icon horizontal-flex-center" />
          <p class="body-2 horizontal-flex-center">User</p>
        </div>
        <div class="account-container" :style="{ backgroundColor: navBarColor() }">
          <button
            class="drop-down-button no-mobile"
            :style="{ backgroundColor: navBarColor() }"
            v-on:click="goToAccount()"
          >
            Profile
          </button>
          <button class="drop-down-button no-mobile" v-if="currentDealer" v-on:click="goToSwitchDealer()">
            Change User
          </button>
          <button class="drop-down-button" v-on:click="goToLogout()">Logout</button>
        </div>
      </div>
    </div>
    <div v-if="showRightNav() && (currentDealer || currentReseller)" class="no-print margin-top-1-05 flex-row">
      <router-link class="no-mobile menu-nav-button" to="/search">Appraisals</router-link>
      <router-link
        v-if="currentDealer && currentDealer.is_manager"
        class="no-mobile menu-nav-button"
        to="/pending-arrival"
      >
        Pending Arrivals
      </router-link>
      <router-link v-if="currentDealer && currentDealer.is_manager" class="no-mobile menu-nav-button" to="/traded-diff">
        My Difference
      </router-link>
      <router-link v-if="currentDealer && currentDealer.is_manager" class="menu-nav-button no-mobile" to="/departments"
        >Departments</router-link
      >
      <router-link v-if="currentDealer && currentDealer.is_manager" class="menu-nav-button no-mobile" to="/permissions"
        >Permissions</router-link
      >
      <router-link v-if="currentDealer && currentDealer.is_manager" class="menu-nav-button no-mobile" to="/tenders"
        >Tenders</router-link
      >
      <router-link
        v-if="currentDealer && currentDealership && currentDealership.is_tender_account && currentDealer.is_manager"
        class="menu-nav-button no-mobile"
        to="/reseller-list"
        >Wholesalers</router-link
      >
      <router-link v-if="currentDealer" class="menu-nav-button no-mobile" to="/global-markets"
        >Live Pricing</router-link
      >
      <a
        v-if="currentDealer && currentDealer.has_autograb_whitelabel_permissions"
        :href="autograbUrl()"
        class="menu-nav-button no-mobile"
        >Autograb</a
      >
      <router-link
        v-if="currentDealership && currentDealership.has_offsite_access"
        class="menu-nav-button no-mobile"
        to="/offsite-forms"
        >Offsites</router-link
      >
      <router-link v-if="currentDealership" class="menu-nav-button no-mobile" to="/internal-documents"
        >Dealer Packs</router-link
      >
      <router-link
        v-if="
          currentDealer &&
          ((currentDealership.is_marketing_account && currentDealer.is_manager) || currentDealer.has_marketing_access)
        "
        class="menu-nav-button no-mobile"
        to="/remarketings"
        >Remarketing</router-link
      >
    </div>
    <!--
    <router-link class="right-nav auto-margin-vertical no-print button" v-if="!showRightNav()" :to="{ name: 'Logout' }"
      >Logout</router-link
    > -->
  </div>
  <router-view id="app-container" />

  <Footer />
  <error-modal v-if="errors.length > 0" :errors="errors" @cancel="removeCurrentError" @cancelAll="removeAllErrors" />
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Footer from "@/components/Footers/Footer.vue";
import {
  setStringDateToStartOfDate,
  setStringDateToEndOfDate,
  dateToFilterFormattedString
} from "../../../helpers/utilityHelpers";
import { endpoints } from "../../../api/axiosHandler.js";
import moment from "moment";

import ErrorModal from "@/components/Modals/v2/ErrorModal.vue";

export default {
  name: "MainLayout",
  components: { ErrorModal, Footer },
  data: function () {
    return {
      scrollValue: 0,
      searchStartDate: null,
      searchEndDate: null
    };
  },
  methods: {
    addFilterPrep: function (filter) {
      this.addFilter(filter);
      this.getForms({ searchURL: this.getURL });
    },
    removeFilterPrep: function (filter) {
      this.removeFilter(filter);
      this.getForms({ searchURL: this.getURL });
    },
    setupVariablesFromFilters: function () {
      if (this.getFilters().date_time_created_gt) {
        let date = moment(new Date(this.getFilters().date_time_created_gt));
        this.searchStartDate = date.format("yyyy-MM-DD");
      } else {
        this.searchStartDate = null;
      }
      if (this.getFilters().date_time_created_lt) {
        let date = moment(new Date(this.getFilters().date_time_created_lt));
        this.searchEndDate = date.format("yyyy-MM-DD");
      } else {
        this.searchEndDate = null;
      }
    },
    goToTables: function () {
      this.$router.push({ name: "Tables" });
    },
    goToReports: function () {
      this.$router.push({ name: "Reports" });
    },
    goToPreBuilts: function () {
      this.$router.push({ name: "PreBuilts" });
    },
    goToAccount: function () {
      this.$router.push({ name: "Account" });
    },
    goToSwitchDealer: function () {
      this.$router.push({ name: "SwitchDealer" });
    },
    goToLogout: function () {
      this.$router.push({ name: "Logout" });
    },
    autograbUrl: function () {
      return this.currentDealership.has_pwah_ag_url ? "https://pwah.autograb.com.au/" : "https://app.iappraise.net.au/";
    },
    navBarColor: function () {
      let grayValue = 250;
      return {
        backgroundColor: "rgba(" + grayValue + ", " + grayValue + ", " + grayValue + ", " + this.scrollValue + ")"
      };
    },
    showRightNav: function () {
      if (this.currentDealership || this.currentReseller || this.currentIsSuperUser) {
        return this.$route.name != "SwitchDealer" && this.$route.name != "FirstTimeLogin";
      }
      return false;
    },
    setupScroll: function () {
      window.addEventListener("scroll", () => {
        this.scrollValue = window.scrollY / 100;
      });
    },
    removeCurrentError: function (index) {
      if (index != null) {
        this.removeError(index);
      }
    },
    ...mapActions({
      addFilter: "formStore/addFilter",
      removeFilter: "formStore/removeFilter",
      getForms: "formStore/getForms",
      removeError: "errorStore/removeError",
      removeAllErrors: "errorStore/removeAllErrors",
      logout: "logout"
    }),
    ...mapGetters({
      getFilters: "formStore/getFilters"
    })
  },
  computed: {
    getURL: function () {
      return endpoints.form;
    },
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer,
      currentDealership: state => state.dealershipStore.currentDealership,
      currentReseller: state => state.resellerStore.currentReseller,
      currentIsSuperUser: state => state.authStore.isSuperUser
    }),
    ...mapGetters({
      errors: "errorStore/errors"
    })
  },
  watch: {
    searchStartDate: function () {
      if (this.searchStartDate) {
        let date = setStringDateToStartOfDate(this.searchStartDate);
        if (date) {
          this.addFilterPrep({
            filter: {
              key: "date_time_created_gt",
              value: date.toISOString()
            }
          });
        }
      } else {
        this.removeFilterPrep({ filter: { key: "date_time_created_gt" } });
      }
    },
    searchEndDate: function () {
      if (this.searchEndDate) {
        let date = setStringDateToEndOfDate(this.searchEndDate);
        if (date) {
          this.addFilterPrep({
            filter: {
              key: "date_time_created_lt",
              value: setStringDateToEndOfDate(this.searchEndDate).toISOString()
            }
          });
        }
      } else {
        this.removeFilterPrep({ filter: { key: "date_time_created_lt" } });
      }
    },
    "$store.state.formStore.filters.date_time_created_gt": {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.searchStartDate = dateToFilterFormattedString(newVal);
        }
      }
    },
    "$store.state.formStore.filters.date_time_created_lt": {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.searchEndDate = dateToFilterFormattedString(newVal);
        }
      }
    }
  },
  mounted: function () {
    this.setupScroll();
    this.setupVariablesFromFilters();
  }
};
</script>

<style lang="scss">
* {
  padding: 0px;
  margin: 0px;
}

// Required for margin reset
p {
  margin: 0px;
}
</style>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.date-inputs {
  @extend .h4;

  border: none;
  position: relative;
  height: 28px;
  background: none;
  display: flex;
}

.date-inputs:after {
  content: url("/assets/img/icon-calendar.svg");
  color: #555;

  height: 28px;
  width: 28px;
  margin-right: 2px;
  margin-left: -20px;
}

.date-inputs::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 28px;
  height: 28px;
  color: transparent;
  background: transparent;
}

.spacer {
  background-color: $DIVIDER_GRAY_COLOR;
  border-left: 1px solid $DIVIDER_GRAY_COLOR;
}

.menu-nav-button {
  @extend .margin-right;
  @extend .body-2-bold;

  color: $ACTION_COLOR;
  text-decoration: none;
  padding: 11px 10px;
  border-radius: 12px;

  &.router-link-exact-active {
    background-color: $YELLOW_COLOR;
  }
}

#nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;

  @media print {
    position: static;
  }
}

.account-container {
  border: 1px solid #bbb;
  display: none;
  position: absolute;
  left: -17px;

  .drop-down-button {
    @extend .body-2;

    display: block;
    flex-grow: 1;
    margin: 0px;

    text-align: left;
    padding: 16px 12px;
    border: none;
    background: white;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: $YELLOW_COLOR;
    }
  }

  &:hover {
    display: flex;
    flex-direction: column;
  }
}

#account-div:hover + .account-container {
  display: flex;
  flex-direction: column;
}

#app-container {
  margin-top: 137px;

  @media print {
    margin-top: 0px;
  }
}
</style>
