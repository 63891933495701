import {
  tradedPurposeString,
  tradedString,
  tenderTitle,
  tenderHasEnded,
  tenderHasStarted,
  pendingStageString,
  carColourString,
  finalOfferString,
  winningOfferValueString,
  winningOfferString,
  etaString,
  dollarString
} from "./formHelpers.js";

import { getAdjustedValue } from "./autograbHelpers.js";

import state from "../../store/index.js";
import { getTopValue } from "./offerHelpers.js";

function availableKeysForSearchPageForDealership(dealership, dealer) {
  var availableKeys = [];

  availableKeys.push("car_registration_number");
  availableKeys.push("car_make");
  availableKeys.push("car_model");
  availableKeys.push("car_model_year");
  availableKeys.push("car_line");
  if (dealer.is_super_manager) {
    availableKeys.push("dealership");
  }
  if (!dealer.is_super_manager) {
    availableKeys.push("department");
  }
  if (dealership.is_tender_account) {
    availableKeys.push("tender");
  }
  availableKeys.push("appraisal_status");
  availableKeys.push("winning_offer");
  availableKeys.push("car_mileage");
  availableKeys.push("car_colour");
  availableKeys.push("date_time_created");
  if (dealer.is_manager) {
    availableKeys.push("final_offer");
  }

  return availableKeys;
}

function availableKeysForSearchPageForDiffToTrade(dealership, dealer) {
  var availableKeys = [];

  availableKeys.push("car_registration_number");
  availableKeys.push("car_make");
  availableKeys.push("car_model");
  availableKeys.push("car_model_year");
  availableKeys.push("car_line");
  if (dealer.is_super_manager) {
    availableKeys.push("dealership");
  }
  if (!dealer.is_super_manager) {
    availableKeys.push("department");
  }
  if (dealership.is_tender_account) {
    availableKeys.push("tender");
  }
  availableKeys.push("car_mileage");
  availableKeys.push("date_time_created");
  if (dealer.is_manager) {
    availableKeys.push("final_offer");
    availableKeys.push("winning_offer");
  }

  availableKeys.push("ag_trade_price");
  availableKeys.push("ag_trade_price_difference");

  return availableKeys;
}

function availableKeysForSearchPageForTender(dealer) {
  var availableKeys = [];

  availableKeys.push("car_registration_number");
  availableKeys.push("car_make");
  availableKeys.push("car_model");
  availableKeys.push("car_model_year");
  availableKeys.push("car_line");
  availableKeys.push("car_mileage");
  availableKeys.push("car_colour");
  if (dealer.is_manager) {
    availableKeys.push("winning_offer");
    availableKeys.push("winning_offer_value");
    availableKeys.push("winner");
    availableKeys.push("traded_offer_to_reserve");
  }
  availableKeys.push("date_time_created");

  return availableKeys;
}

function availableKeysForSearchPageForTenderTheoretical(dealer) {
  var availableKeys = [];

  availableKeys.push("car_registration_number");
  availableKeys.push("car_make");
  availableKeys.push("car_model");
  availableKeys.push("car_model_year");
  availableKeys.push("car_line");
  availableKeys.push("car_mileage");
  availableKeys.push("car_colour");
  if (dealer.is_manager) {
    availableKeys.push("winning_offer");
    availableKeys.push("winning_offer_value");
    availableKeys.push("winner");
    availableKeys.push("highest_offer");
    availableKeys.push("highest_offer_to_reserve");
  }
  availableKeys.push("date_time_created");

  return availableKeys;
}

function availableKeysForSearchPageForTenderMeets(dealer) {
  var availableKeys = [];

  availableKeys.push("car_registration_number");
  availableKeys.push("car_make");
  availableKeys.push("car_model");
  availableKeys.push("car_model_year");
  availableKeys.push("car_line");
  availableKeys.push("car_mileage");
  availableKeys.push("car_colour");
  if (dealer.is_manager) {
    availableKeys.push("winning_offer");
    availableKeys.push("winning_offer_value");
    availableKeys.push("winner");
    availableKeys.push("highest_offer");
    availableKeys.push("highest_offer_meets_reserve");
  }
  availableKeys.push("date_time_created");

  return availableKeys;
}

function availableKeysForSearchPageForPendingArrivals(dealer) {
  var availableKeys = [];

  availableKeys.push("car_registration_number");
  availableKeys.push("car_make");
  availableKeys.push("car_model");
  availableKeys.push("car_model_year");
  availableKeys.push("car_line");
  if (dealer.is_super_manager) {
    availableKeys.push("dealership");
  }
  if (!dealer.is_super_manager) {
    availableKeys.push("department");
  }
  availableKeys.push("car_mileage");
  availableKeys.push("car_colour");
  availableKeys.push("final_offer");
  availableKeys.push("traded_purpose");
  availableKeys.push("traded_eta");

  return availableKeys;
}

function availableKeysForSearchPageForReseller() {
  var availableKeys = [];

  availableKeys.push("car_registration_number");
  availableKeys.push("car_make");
  availableKeys.push("car_model");
  availableKeys.push("car_model_year");
  availableKeys.push("car_line");
  availableKeys.push("dealership");

  availableKeys.push("appraisal_status");
  availableKeys.push("car_mileage");
  availableKeys.push("car_colour");
  availableKeys.push("date_time_created");
  availableKeys.push("offer_status");

  return availableKeys;
}

function availableKeysForRemarketing() {
  var availableKeys = [];

  availableKeys.push("car_registration_number");
  availableKeys.push("car_make");
  availableKeys.push("car_model");
  availableKeys.push("car_model_year");
  availableKeys.push("car_line");

  availableKeys.push("dealer");
  availableKeys.push("customer_name");
  availableKeys.push("customer_work_phone");
  availableKeys.push("car_mileage");
  availableKeys.push("car_colour");
  availableKeys.push("date_time_created");
  availableKeys.push("remarketing_response");

  return availableKeys;
}

function availableKeysForMyMarket() {
  var availableKeys = [];

  availableKeys.push("car_registration_number");
  availableKeys.push("car_make");
  availableKeys.push("car_model");
  availableKeys.push("car_model_year");
  availableKeys.push("car_line");
  availableKeys.push("date_time_created");
  availableKeys.push("dealership");
  availableKeys.push("final_offer");
  availableKeys.push("car_mileage");

  return availableKeys;
}

function availableKeysForReports() {
  var availableKeys = [];

  availableKeys.push("car_registration_number");
  availableKeys.push("car_make");
  availableKeys.push("car_model");
  availableKeys.push("car_model_year");
  availableKeys.push("car_line");
  availableKeys.push("date_time_created");
  availableKeys.push("dealership");
  availableKeys.push("final_offer");
  availableKeys.push("car_mileage");
  availableKeys.push("purity");

  return availableKeys;
}

function indicatorClassForTender(start_time, end_time) {
  if (tenderHasEnded(end_time)) {
    return "lost";
  } else if (start_time && !tenderHasStarted(start_time)) {
    return "awaiting";
  } else if (start_time) {
    return "yes";
  }
  return "no";
}

const options = [
  {
    title: "Rego",
    key: "car_registration_number",
    type: "raw",
    sortFunc: (a, b) => {
      return a.car_registration_number.toUpperCase() > b.car_registration_number.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "Year",
    key: "car_model_year",
    type: "raw",
    sortFunc: (a, b) => {
      return a.car_model_year > b.car_model_year ? 1 : -1;
    }
  },
  {
    title: "Make",
    key: "car_make",
    type: "raw",
    sortFunc: (a, b) => {
      return a.car_make.toUpperCase() > b.car_make.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "Model",
    key: "car_model",
    type: "raw",
    sortFunc: (a, b) => {
      return a.car_model.toUpperCase() > b.car_model.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "Line",
    key: "car_line",
    type: "raw",
    sortFunc: (a, b) => {
      return a.car_line.toUpperCase() > b.car_line.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "User",
    key: "dealer",
    type: "complex",
    content: form => {
      if (typeof form.dealer === "object") {
        return (form.dealer ? form.dealer.first_name + " " : "—") + (form.dealer ? form.dealer.last_name : "");
      }
      if (state.state.authStore.isDealership) {
        let dealer = state.getters["dealershipStore/dealers"].filter(dealer => {
          return dealer.url == form.dealer;
        })[0];
        if (dealer) {
          return (dealer ? dealer.first_name + " " : "—") + (dealer ? dealer.last_name : "");
        }
      }

      return "—";
    },
    sortFunc: (a, b) => {
      let name1 = (a.dealer ? a.dealer.first_name : "—") + (a.dealer ? a.dealer.last_name : "");
      let name2 = (b.dealer ? b.dealer.first_name : "—") + (b.dealer ? b.dealer.last_name : "");
      return name1.toUpperCase() > name2.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "Starting User",
    key: "starting_dealer",
    type: "complex",
    content: form => {
      return (
        (form.starting_dealer ? form.starting_dealer.first_name + " " : "—") +
        (form.starting_dealer ? form.starting_dealer.last_name : "")
      );
    },
    sortFunc: (a, b) => {
      let name1 =
        (a.starting_dealer ? a.starting_dealer.first_name : "—") +
        (a.starting_dealer ? a.starting_dealer.last_name : "");
      let name2 =
        (b.starting_dealer ? b.starting_dealer.first_name : "—") +
        (b.starting_dealer ? b.starting_dealer.last_name : "");
      return name1.toUpperCase() > name2.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "Customer Name",
    key: "customer_name",
    type: "raw",
    sortFunc: (a, b) => {
      return a.customer_name.toUpperCase() > b.customer_name.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "Customer Mobile",
    key: "customer_work_phone",
    type: "raw",
    sortFunc: (a, b) => {
      return a.customer_work_phone.toUpperCase() > b.customer_work_phone.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "Tender",
    key: "tender",
    type: "indicator",
    content: form => {
      return tenderTitle(form);
    },
    indicatorClass: form => {
      return indicatorClassForTender(form.tender_start_time, form.tender_end_time);
    },
    sortFunc: (a, b) => {
      return a.tender_start_time < b.tender_start_time ? 1 : -1;
    }
  },
  {
    title: "Mileage (km)",
    key: "car_mileage",
    type: "number",
    sortFunc: (a, b) => {
      return a.car_mileage < b.car_mileage ? 1 : -1;
    }
  },
  {
    title: "Colour",
    key: "car_colour",
    type: "complex",
    content: form => {
      return carColourString(form);
    },
    sortFunc: (a, b) => {
      return a.car_colour.toUpperCase() < b.car_colour.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "+/- Reserve (Theoretical)",
    key: "highest_offer_to_reserve",
    type: "indicator",
    content: form => {
      return dollarString(form.highest_offer_to_reserve);
    },
    indicatorClass: form => {
      if (form.highest_offer_to_reserve) {
        if (form.highest_offer_to_reserve > 0) {
          return "yes";
        } else {
          return "lost";
        }
      }
      return "no";
    },
    sortFunc: (a, b) => {
      if (a.highest_offer_to_reserve && b.highest_offer_to_reserve) {
        return a.highest_offer_to_reserve < b.highest_offer_to_reserve ? 1 : -1;
      }

      return 0;
    }
  },
  {
    title: "Meets Reserve (Theoretical)",
    key: "highest_offer_meets_reserve",
    type: "indicator",
    content: form => {
      if (form.highest_offer_to_reserve) {
        if (form.highest_offer_to_reserve > 0) {
          return "Above Reserve";
        } else {
          return "Below Reserve";
        }
      }
      return "No Bids";
    },
    indicatorClass: form => {
      if (form.highest_offer_to_reserve) {
        if (form.highest_offer_to_reserve > 0) {
          return "yes";
        } else {
          return "lost";
        }
      }
      return "no";
    },
    sortFunc: (a, b) => {
      if (a.highest_offer_to_reserve && b.highest_offer_to_reserve) {
        return a.highest_offer_to_reserve < b.highest_offer_to_reserve ? 1 : -1;
      }

      return 0;
    }
  },
  {
    title: "Highest Offer",
    key: "highest_offer",
    type: "complex",
    content: form => {
      return dollarString(form.highest_offer);
    },
    sortFunc: (a, b) => {
      return a < b ? 1 : -1;
    }
  },
  {
    title: "Traded",
    key: "winning_offer",
    type: "indicator",
    content: form => {
      return tradedString(form);
    },
    indicatorClass: form => {
      if (form.winning_offer) {
        return "yes";
      }
      if (form.is_lost) {
        return "lost";
      }
      return "no";
    },
    sortFunc: (a, b) => {
      var value1 = 0;
      let value2 = 0;

      if (a.winning_offer) {
        value1 = 2;
      } else if (a.is_lost) {
        value1 = 1;
      }

      if (b.winning_offer) {
        value2 = 2;
      } else if (b.is_lost) {
        value2 = 1;
      }

      return value2 < value1 ? 1 : -1;
    }
  },
  {
    title: "+/- Reserve (Traded)",
    key: "traded_offer_to_reserve",
    type: "indicator",
    content: form => {
      return dollarString(form.traded_offer_to_reserve);
    },
    indicatorClass: form => {
      if (form.traded_offer_to_reserve) {
        if (form.traded_offer_to_reserve > 0) {
          return "yes";
        } else {
          return "lost";
        }
      }
      return "no";
    },
    sortFunc: (a, b) => {
      if (a.traded_offer_to_reserve && b.traded_offer_to_reserve) {
        return a.traded_offer_to_reserve < b.traded_offer_to_reserve ? 1 : -1;
      }

      return 0;
    }
  },
  {
    title: "Traded Offer",
    key: "winning_offer_value",
    type: "complex",
    content: form => {
      return winningOfferValueString(form);
    },
    sortFunc: (a, b) => {
      let aValue = a.winning_offer ? getTopValue(a.winning_offer, true) : 0;
      let bValue = b.winning_offer ? getTopValue(b.winning_offer, true) : 0;
      return aValue < bValue ? 1 : -1;
    }
  },
  {
    title: "Final Offer",
    key: "final_offer",
    type: "complex",
    content: form => {
      return finalOfferString(form);
    },
    sortFunc: (a, b) => {
      let aValue = a.final_offer ? getTopValue(a.final_offer, true) : 0;
      let bValue = b.final_offer ? getTopValue(b.final_offer, true) : 0;
      return aValue < bValue ? 1 : -1;
    }
  },
  {
    title: "Rec. Trade Price",
    key: "ag_trade_price",
    type: "complex",
    content: form => {
      return dollarString(getAdjustedValue(form.auto_grab_fetched_data));
    },
    sortFunc: (a, b) => {
      return a < b ? 1 : -1;
    }
  },
  {
    title: "Difference",
    key: "ag_trade_price_difference",
    type: "complex",
    content: form => {
      let final_value = form.final_offer ? getTopValue(form.final_offer, true) : 0;
      if (form.auto_grab_fetched_data && final_value) {
        let adjustedValue = getAdjustedValue(form.auto_grab_fetched_data);
        return dollarString(final_value - adjustedValue);
      }
      return "N/A";
    },
    sortFunc: (a, b) => {
      return a < b ? 1 : -1;
    }
  },
  {
    title: "Winner",
    key: "winner",
    type: "complex",
    content: form => {
      return winningOfferString(form);
    },
    sortFunc: (a, b) => {
      return winningOfferString(a).toUpperCase() < winningOfferString(b).toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "Status",
    key: "appraisal_status",
    type: "complex",
    content: form => {
      return form.appraisal_status + pendingStageString(form);
    },
    sortFunc: (a, b) => {
      return a.appraisal_status.toUpperCase() < b.appraisal_status.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "Date Created",
    key: "date_time_created",
    type: "date",
    sortFunc: (a, b) => {
      return a.date_time_created < b.date_time_created ? 1 : -1;
    }
  },
  {
    title: "Dealership",
    key: "dealership",
    type: "complex",
    content: form => {
      return form.dealership.name;
    },
    sortFunc: (a, b) => {
      return a.dealership.name.toUpperCase() > b.dealership.name.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "Department",
    key: "department",
    type: "complex",
    content: form => {
      return form.department ? form.department.title : "—";
    },
    sortFunc: (a, b) => {
      let name1 = a.department ? a.department.title : "—";
      let name2 = b.department ? b.department.title : "—";
      return name1.toUpperCase() > name2.toUpperCase() ? 1 : -1;
    }
  },
  {
    title: "Intention",
    key: "traded_purpose",
    type: "complex",
    content: form => {
      return tradedPurposeString(form.traded_purpose);
    },
    sortFunc: (a, b) => {
      return tradedPurposeString(a.traded_purpose).toUpperCase() < tradedPurposeString(b.traded_purpose).toUpperCase()
        ? 1
        : -1;
    }
  },
  {
    title: "ETA",
    key: "traded_eta",
    type: "complex",
    content: form => {
      return etaString(form);
    },
    sortFunc: (a, b) => {
      if (a.traded_eta && b.traded_eta) {
        return a.traded_eta < b.traded_eta ? 1 : -1;
      }
      if (a.traded_eta) {
        return 1;
      }
      return -1;
    }
  },
  {
    title: "Offer",
    key: "offer_status",
    type: "raw",
    sortFunc: (a, b) => {
      return a.offer_status < b.offer_status ? 1 : -1;
    }
  },
  {
    title: "Purity",
    key: "purity",
    type: "complex",
    content: form => {
      return form.purity ? form.purity + "%" : "—";
    },
    sortFunc: (a, b) => {
      return a.purity < b.purity ? 1 : -1;
    }
  },
  {
    title: "Response",
    key: "remarketing_response",
    type: "indicator",
    content: form => {
      return form.message.is_accepted
        ? "Wants Contacting"
        : form.message.error_message
        ? form.message.error_message
        : "Hasn't Responded";
    },
    indicatorClass: form => {
      return form.message.is_accepted ? "yes" : form.message.error_message ? "lost" : "no";
    },
    sortFunc: (a, b) => {
      return a.message.is_accepted < b.message.is_accepted ? 1 : -1;
    }
  }
];

export {
  availableKeysForSearchPageForDealership,
  availableKeysForSearchPageForDiffToTrade,
  availableKeysForSearchPageForReseller,
  availableKeysForSearchPageForPendingArrivals,
  availableKeysForSearchPageForTender,
  availableKeysForSearchPageForTenderTheoretical,
  availableKeysForSearchPageForTenderMeets,
  indicatorClassForTender,
  availableKeysForRemarketing,
  availableKeysForMyMarket,
  availableKeysForReports,
  options
};
