<template>
  <div>
    <div id="arrivals-page">
      <h1 class="h1-title">My Difference</h1>
      <search-bar class="margin-right margin-top-2" availableFilterMode="dealership"></search-bar>
      <filter-bar />
      <div class="results-table-container">
        <results-table
          class="margin-top margin-right"
          availableSearchMode="diff_to_traded"
          ref="resultsTable"
          :show-options="true"
        >
          <template #default="{ form }">
            <img
              v-on:click="dropdownForm = dropdownForm == form ? null : form"
              src="/assets/img/icon-more-dots.svg"
              class="small-icon pointer-cursor"
            />
            <div v-if="dropdownForm == form" class="iappraise-dropdown">
              <div class="iappraise-dropdown-button" v-on:click="requestAutoGrabFetchData(form)">View Autograb</div>
            </div>
          </template>
        </results-table>
      </div>
      <autograb-box-modal
        v-if="selectedForm"
        :form="selectedForm"
        @cancel="selectedForm = null"
        @newAutoGrabData="newAutoGrabData"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { endpoints } from "../../api/axiosHandler.js";

import ResultsTable from "../../components/Views/v2/ResultsTable.vue";
import SearchBar from "../../components/Views/v2/SearchBar.vue";
import FilterBar from "../../components/Views/v2/FilterBar.vue";
import AutograbBoxModal from "../../components/Modals/v2/AutograbBoxModal.vue";

export default {
  name: "DiffToTradeForms",
  components: { ResultsTable, SearchBar, FilterBar, AutograbBoxModal },
  data: function () {
    return {
      dropdownForm: null,
      selectedForm: null
    };
  },
  methods: {
    requestAutoGrabFetchData: function (form) {
      this.dropdownForm = null;
      this.selectedForm = form;
    },
    newAutoGrabData: function (data) {
      this.selectedForm.auto_grab_fetched_data = data;
    },
    ...mapActions({
      getForms: "formStore/getForms",
      setFormsUrl: "formStore/setCurrentUrl"
    })
  },
  mounted: function () {
    let app = this;
    this.setFormsUrl(endpoints.form + "diff-to-trade/");
    this.$nextTick(() => {
      app.getForms({});
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#arrivals-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
