<template>
  <base-modal-view title="Autograb Data" @cancel="cancelClicked">
    <autograb-box
      class="margin-top min-height"
      :form="form"
      :auto_grab_fetched_data="form.auto_grab_fetched_data"
      @newAutoGrabData="newAutoGrabData"
    />
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import AutograbBox from "../../Views/v2/AutograbBox.vue";

export default {
  name: "AutograbBoxModal",
  components: { BaseModalView, AutograbBox },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    newAutoGrabData: function (data) {
      this.$emit("newAutoGrabData", data);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.min-height {
  min-height: 200px;
}
</style>
