<template>
  <div>
    <div id="offsite-forms-page">
      <h1 class="h1-title">Offsite Appraisals</h1>
      <div v-if="!currentDealership.has_offsite_access">
        <div class="flex-column">
          <div class="flex-column horizontal-flex-center margin-bottom-4 margin-top-4">
            <img class="splash-img horizontal-flex-center" src="/assets/img/splash2.jpg" />
            <h2 class="h2-title margin-top-1-05 horizontal-flex-center">Welcome To Offsites</h2>
            <p class="body-1 margin-top-05">
              You don't seem to be setup with the iAppraise Offsite system. Contact Us to learn more.
            </p>
          </div>
        </div>
      </div>
      <div v-if="currentDealership.has_offsite_access">
        <div class="flex-row margin-top-2">
          <secondary-button
            icon="/assets/img/icon-plus.svg"
            class="margin-right"
            title="Request an Offsite Appraisal"
            v-on:click="requestingNewOffsiteForm = true"
          />
          <search-bar
            class="margin-right flex-grow"
            availableFilterMode=""
            placeholder="Search for name, phone number or email"
            :emitActions="true"
            @applyFilter="applyFilter"
          />
        </div>
        <table class="results-table margin-top">
          <tr class="header">
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Appraisal</th>
          </tr>
          <tr class="data-row" v-for="offsiteForm in slicedOffsiteForms()" v-bind:key="offsiteForm.uuid">
            <td>{{ offsiteForm.customer_name }}</td>
            <td>{{ offsiteForm.customer_work_phone }}</td>
            <td>{{ offsiteForm.customer_email }}</td>
            <td style="width: 200px">
              <secondary-button
                :title="offsiteForm.form ? 'Go to Appraisal' : 'Awaiting Customer'"
                :disabled="!offsiteForm.form"
                v-on:click="goToForm(offsiteForm)"
              />
              <secondary-button
                class="margin-left"
                v-if="offsiteForm.kadabra_conversation"
                title="View Conversation"
                v-on:click="offsiteFormViewing = offsiteForm"
              />
            </td>
          </tr>
        </table>
        <paginator-bar
          v-model:page="page"
          :pageBounds="pageBounds()"
          :loadedItemsCount="offsiteForms.length"
          :loading="loading"
          :hasMorePages="nextUrl != null"
          @loadMore="getNextOffsiteForms()"
        />
      </div>

      <create-offsite-form-modal
        v-if="requestingNewOffsiteForm"
        @cancel="requestingNewOffsiteForm = false"
        @createOffsite="requestOffsiteForm"
      />

      <OffsiteFormConversationModal
        v-if="offsiteFormViewing"
        :offsiteForm="offsiteFormViewing"
        @cancel="offsiteFormViewing = null"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import { fetchOffsiteForms, createOffsiteForm } from "../../../api/OffsiteForms.js";
import { axiosInstance } from "../../../api/axiosHandler.js";
import { pageBounds } from "../../../helpers/v2/utilityHelpers.js";

import SearchBar from "../../../components/Views/v2/SearchBar.vue";
import SecondaryButton from "../../../components/Buttons/v2/SecondaryButton.vue";
import PaginatorBar from "../../../components/Common/PaginatorBar.vue";
import CreateOffsiteFormModal from "../../../components/Modals/v2/CreateOffsiteFormModal.vue";
import OffsiteFormConversationModal from "./Modals/OffsiteFormConversationModal.vue";
import _ from "lodash";

export default {
  name: "GlobalMarkets",
  components: { SecondaryButton, CreateOffsiteFormModal, SearchBar, PaginatorBar, OffsiteFormConversationModal },
  data: function () {
    return {
      searchDict: {
        title: null
      },
      offsiteForms: [],
      offsiteFormsCount: 0,
      axiosCancelToken: null,
      nextUrl: null,
      loading: false,
      requestingNewOffsiteForm: false,
      loadingRequestNewOffsiteForm: false,
      offsiteFormViewing: null,

      page: 1
    };
  },
  methods: {
    fetchOffsiteForms: function () {
      if (this.axiosCancelToken) {
        if (this.axiosCancelToken.cancel) {
          this.axiosCancelToken.cancel("New Request");
        }
      }
      this.axiosCancelToken = axios.CancelToken.source();
      this.loading = true;
      fetchOffsiteForms(this.searchDict, this.axiosCancelToken)
        .then(response => {
          this.loading = false;
          this.offsiteForms = response.results;
          this.offsiteFormsCount = response.count;
          this.nextUrl = response.next;
        })
        .catch(error => {
          this.loading = false;
          if (!error.__CANCEL__) {
            this.addError(error);
          }
        });
    },
    slicedOffsiteForms: function () {
      let bounds = this.pageBounds();
      let toReturn = this.offsiteForms;
      return toReturn.slice(bounds.lowBound, bounds.highBound);
    },
    pageBounds() {
      return pageBounds(this.page);
    },
    goToForm: function (offsiteForm) {
      if (offsiteForm.form) {
        let routeData = this.$router.resolve({
          name: "Form",
          params: { id: offsiteForm.form }
        });
        window.open(routeData.href, "_blank");
      }
    },
    requestOffsiteForm: function (searchDict) {
      if (this.loadingRequestNewOffsiteForm) {
        return;
      }
      this.loadingRequestNewOffsiteForm = true;
      createOffsiteForm(searchDict)
        .then(offsiteForm => {
          this.loadingRequestNewOffsiteForm = false;
          this.requestingNewOffsiteForm = false;
          this.offsiteForms.unshift(offsiteForm);
          this.offsiteFormsCount += 1;
        })
        .catch(error => {
          this.addError(error);
          this.loadingRequestNewOffsiteForm = false;
        });
    },
    clearFilters: function () {
      this.searchDict.title = null;
    },
    getNextOffsiteForms() {
      if (this.nextUrl) {
        if (this.loading) {
          return;
        }
        this.loading = true;
        axiosInstance()
          .get(this.nextUrl, null)
          .then(response => {
            this.loading = false;
            this.nextUrl = response.data.next;
            response.data.results.forEach(offsiteForm => {
              this.offsiteForms.push(offsiteForm);
            });
          })
          .catch(error => {
            this.loading = false;
            this.addError(error);
          });
      }
    },
    applyFilter: function (filter) {
      if (filter.filter.key == "quick_search") {
        this.searchDict.title = filter.filter.value;
      } else {
        var newFilters = _.cloneDeep(this.filters);
        newFilters[filter.filter.key] = filter.filter.value;
        this.filters = newFilters;
      }
    },
    removeFilter: function (filter) {
      delete this.filters[filter.key];
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  computed: {
    ...mapState({
      currentDealership: state => state.dealershipStore.currentDealership
    })
  },
  watch: {
    searchDict: {
      handler: function () {
        this.fetchOffsiteForms();
      },
      deep: true
    }
  },
  mounted: function () {
    if (this.currentDealership.has_offsite_access) {
      this.fetchOffsiteForms();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#offsite-forms-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}

.splash-img {
  width: 326px;
  height: 170px;
  object-fit: cover;
}
</style>
