<template>
  <div>
    <div id="form-page">
      <div v-if="form">
        <div class="floating flex-column no-print">
          <p
            v-for="data in formDataBoxTitles()"
            class="show-on-hover body-2-bold margin-bottom-05 pointer-cursor"
            v-bind:key="data"
            v-on:click="scrollToId(data)"
          >
            {{ data }}
          </p>
        </div>
        <div class="flex-row no-print">
          <tertiary-button icon="/assets/img/icon-chevron-left-black.svg" title="Search" v-on:click="backToSearch()" />
          <div class="margin-left-auto margin-right" style="display: flex">
            <tertiary-button
              class="margin-right-05"
              title="Previous Appraisal"
              v-if="previousAppraisal"
              v-on:click="goToPreviousAppraisal()"
            />
            <tertiary-button title="Next Appraisal" v-if="nextAppraisal" v-on:click="goToNextAppraisal()" />
          </div>
        </div>

        <div class="flex-row">
          <div>
            <div class="flex-row">
              <p class="h1-title">{{ form.car_make }}, {{ form.car_model }}</p>
              <status-alert
                v-if="getWarningsAndDangers()"
                class="margin-left vertical-flex-center pointer-cursor"
                alertLevel="warning"
                alertTitle="Warnings"
                v-on:click="showWarningModal = true"
              />
              <status-alert
                v-if="form.odometer_rollbacks && form.odometer_rollbacks.length > 0"
                class="margin-left vertical-flex-center pointer-cursor"
                alertLevel="warning"
                alertTitle="Suspected Odometer Rollback"
                v-on:click="showOdometerRollbackModal = true"
              />
              <status-alert
                v-if="currentDealer && currentDealer.is_manager && form.auto_grab_listing_history"
                class="margin-left vertical-flex-center pointer-cursor"
                :alertLevel="autograbIsOnTheInternet() ? 'danger' : 'good'"
                :alertTitle="
                  autograbIsOnTheInternet() ? 'Vehicle is on the internet' : 'Vehicle is not on the internet'
                "
                v-on:click="showOnlineSourcesModal = true"
              />
              <status-alert
                v-if="checkingOnlinePresence"
                class="margin-left vertical-flex-center pointer-cursor"
                alertLevel="warning"
                alertTitle="Checking the internet..."
              />
              <status-alert
                v-if="form.upgrade_interest && form.upgrade_interest.sell"
                v-on:click="showUpgradeInterestModal = true"
                class="margin-left vertical-flex-center pointer-cursor"
                alertLevel="good"
                alertTitle="Customer wants to sell"
              />
              <status-alert
                v-if="form.upgrade_interest && form.upgrade_interest.upgrade"
                v-on:click="showUpgradeInterestModal = true"
                class="margin-left vertical-flex-center pointer-cursor"
                alertLevel="good"
                alertTitle="Customer wants to upgrade"
              />
            </div>
            <p class="h3-subtitle margin-top-05">
              {{ form.car_year_of_manufacture || form.car_model_year }} {{ form.car_make }} {{ form.car_model }},
              {{ form.car_line }}
              {{ form.car_body }}
            </p>
            <div class="flex-row margin-top-05" v-if="currentDealer">
              <status-indicator v-if="form.winning_offer" alertLevel="traded" alertTitle="Traded" />
              <status-indicator
                v-else-if="form.is_lost"
                alertLevel="lost"
                :alertTitle="'Lost: ' + form.reasons_lost + (form.lost_amount ? ' | Wanted: $' + form.lost_amount : '')"
              />
              <status-indicator v-else-if="form.is_archived" alertLevel="archived" alertTitle="Archived" />
              <div v-else class="flex-row">
                <status-indicator alertLevel="not_traded" alertTitle="Not Traded" />
                <div class="horizontal-rule margin-left margin-right"></div>
                <p class="h3-subtitle">{{ form.appraisal_status + pendingStageString(form) }}</p>
              </div>
              <div class="flex-row" v-if="form.is_trash">
                <div class="horizontal-rule margin-left margin-right"></div>
                <status-indicator alertLevel="lost" alertTitle="Trashed" />
              </div>

              <div class="flex-row" v-if="form.is_ready_for_traded && !form.winning_offer">
                <div class="horizontal-rule margin-left margin-right"></div>
                <p class="h3-subtitle">{{ currentDealer.is_manager ? "Ready to Mark Traded" : "Marked for Trade" }}</p>
              </div>

              <div class="flex-row" v-if="currentDealer && currentDealer.is_manager && form.is_favourite">
                <div class="horizontal-rule margin-left margin-right"></div>
                <p class="h3-subtitle">Favourite</p>
              </div>

              <div
                class="flex-row"
                v-if="currentDealer && currentDealer.is_manager && form.final_offer && !form.winning_offer"
              >
                <div class="horizontal-rule margin-left margin-right"></div>
                <p class="h3-subtitle">Final Offer Price: ${{ getTopValue(form.final_offer) }}</p>
              </div>
              <div class="flex-row" v-if="currentDealer && currentDealer.is_manager && form.winning_offer">
                <div class="horizontal-rule margin-left margin-right"></div>
                <p class="h3-subtitle">Traded Price ${{ getTopValue(form.winning_offer) }}</p>
              </div>
              <div class="flex-row" v-if="showVehicleRating">
                <div class="horizontal-rule margin-left margin-right"></div>
                <img
                  style="height: 24px"
                  v-for="i in form.vehicle_rating"
                  :key="i"
                  src="/assets/img/icon-star-filled.svg"
                  alt=""
                />
                <img
                  style="height: 24px"
                  v-for="i in 5 - form.vehicle_rating"
                  :key="i"
                  src="/assets/img/icon-star-filled-light.svg"
                  alt=""
                />
              </div>
              <div class="flex-row" v-if="tenderValue && !editingIndex">
                <div class="horizontal-rule margin-left margin-right"></div>
                <p class="h3-subtitle">{{ tenderValue }}</p>
              </div>
            </div>
            <div class="flex-row margin-top-05" v-if="currentReseller && offerForLoggedInReseller()">
              <status-indicator
                v-if="form.appraisal_status == 'Active'"
                alertLevel="pending"
                alertTitle="Offer Requested"
              />
              <status-indicator
                v-else-if="offerForLoggedInReseller().price"
                alertLevel="traded"
                :alertTitle="'Priced: $' + offerForLoggedInReseller().price"
              />
              <status-indicator
                v-else-if="offerForLoggedInReseller().passed"
                alertLevel="archived"
                alertTitle="Passed"
              />
              <status-indicator v-else alertLevel="lost" alertTitle="Missed" />
            </div>
            <div class="flex-row margin-top-1-05">
              <div class="position-relative">
                <secondary-button
                  v-if="currentDealer"
                  class="min-width-160"
                  title="Change Status"
                  :selected="showChangeStatusDropdown"
                  v-on:click="
                    !showChangeStatusDropdown
                      ? (() => {
                          closeAllDropdowns();
                          showChangeStatusDropdown = true;
                        })()
                      : (() => {
                          closeAllDropdowns();
                        })()
                  "
                />
                <div v-if="showChangeStatusDropdown" class="dropdown shadow body-2 padding-vertical-025">
                  <div
                    class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                    v-on:click="statusButton.action"
                    v-for="statusButton in statusButtons()"
                    v-bind:key="statusButton"
                  >
                    {{ statusButton.title }}
                  </div>
                </div>
                <div class="dropdown shadow body-2 padding-vertical-025" v-if="tradedPurposeModalShowing">
                  <div
                    v-for="(tp, i) in tradedPurposeOptions()"
                    v-bind:key="tp"
                    v-on:click="markFinalAsTradedWithPurpose(i)"
                    class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                  >
                    {{ tp }}
                  </div>
                  <div
                    v-on:click="tradedPurposeModalShowing = false"
                    class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                  >
                    Cancel
                  </div>
                </div>
              </div>
              <div class="position-relative margin-left-05">
                <secondary-button
                  class="min-width-160"
                  title="Generate Report"
                  :selected="showGenerateReportsDropdown"
                  v-on:click="
                    !showGenerateReportsDropdown
                      ? (() => {
                          closeAllDropdowns();
                          showGenerateReportsDropdown = true;
                        })()
                      : (() => {
                          closeAllDropdowns();
                        })()
                  "
                />
                <div v-if="showGenerateReportsDropdown" class="dropdown shadow body-2 padding-vertical-025">
                  <div
                    class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                    v-on:click="statusButton.action"
                    v-for="statusButton in reportButtons()"
                    v-bind:key="statusButton"
                  >
                    {{ statusButton.title }}
                  </div>
                </div>
              </div>
              <div class="position-relative margin-left-05">
                <secondary-button
                  v-if="currentDealer"
                  class="min-width-160"
                  title="More Options"
                  :selected="showMoreOptionsDropdown"
                  v-on:click="
                    !showMoreOptionsDropdown
                      ? (() => {
                          closeAllDropdowns();
                          showMoreOptionsDropdown = true;
                        })()
                      : (() => {
                          closeAllDropdowns();
                        })()
                  "
                />
                <div v-if="showMoreOptionsDropdown" class="dropdown shadow body-2 padding-vertical-025">
                  <div
                    class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                    v-on:click="statusButton.action"
                    v-for="statusButton in moreButtons()"
                    v-bind:key="statusButton"
                  >
                    {{ statusButton.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="margin-left-auto margin-right pointer-cursor" v-if="fullBodyPhoto()">
            <viewer
              @shown="showingImageViewer = true"
              @hide="showingImageViewer = false"
              :images="form.general_photos.map(gp => gp.photo)"
            >
              <div class="flex-row position-relative">
                <img class="full-photo" :src="fullBodyPhoto().photo" />
                <div class="flex-column other-photo-container">
                  <img class="other-photo" v-for="photo in otherPhotos()" v-bind:key="photo" :src="photo.photo" />
                </div>
                <div v-if="otherPhotos().length > 3" class="more-photo-indicator flex-row">
                  <div class="flex-center flex-column">
                    <p class="more-photo-text">More Photos</p>
                    <img class="more-photo-img horizontal-flex-center" src="/assets/img/icon-down-arrow.svg" alt="" />
                  </div>
                </div>
              </div>
            </viewer>
          </div>
        </div>

        <div class="seperator margin-top-2 margin-bottom-2-05"></div>

        <div class="pagebreak form-content-container" v-for="i in calculateIndexForFormData()" v-bind:key="i">
          <div class="form-content" v-for="(section, j) in formDataForIndex(i)" v-bind:key="j">
            <div class="flex-row margin-bottom-1-05 height-40">
              <p :id="section.title" class="h3-title vertical-flex-center scroll-margin-top">
                {{ section.title ? section.title : "&nbsp;" }}
              </p>
              <secondary-button
                v-if="!editingIndex && section.editable && (!section.wide || (section.wide && j != 0))"
                class="edit-button no-print margin-left-auto min-width-96"
                title="Edit"
                v-on:click="editingIndex = [i, j].toString()"
              />
              <secondary-button
                v-if="editingIndex && editingIndex == [i, j].toString() && (!section.wide || (section.wide && j != 0))"
                class="edit-button no-print margin-left-auto min-width-96"
                title="Save"
                v-on:click="saveForm()"
              />
              <secondary-button
                v-if="section?.isPpsr && (!section.wide || (section.wide && j != 0))"
                class="edit-button no-print margin-left-auto min-width-96"
                title="Request"
                v-on:click="showPPSRModal()"
              />
              <secondary-button
                v-if="
                  section?.isFactoryVehicleOptions &&
                  (!section.wide || (section.wide && j != 0)) &&
                  form.auto_grab_factory_vehicle_options != null &&
                  form.auto_grab_factory_vehicle_options.length == 0
                "
                class="edit-button no-print margin-left-auto min-width-96"
                :title="fetchingFetchFactoryVehicleOptions ? 'Fetching...' : 'Fetch Factory Vehicle Options'"
                v-on:click="fetchFactoryVehicleOptions()"
              />
            </div>
            <table class="form-table">
              <tr
                v-for="(item, k) in section.data.filter(dict => !dict.isHidden)"
                v-bind:key="k"
                v-on:click="item.onClick ? item.onClick($router) : () => {}"
                :class="{ 'pointer-cursor': item.onClick }"
              >
                <td class="heading-td">{{ item.title }}</td>
                <td
                  v-if="!isEditing(i, j, section, item)"
                  class="content-td flex-row"
                  :class="{ 'error-text': item.is_error }"
                >
                  <p class="flex-grow">{{ (item.content ? item.content : form[item.key]) || "—" }}</p>
                  <img
                    v-if="item.warning"
                    src="/assets/img/icon-warning.svg"
                    :title="item.warning"
                    class="vertical-flex-center margin-left-025"
                  />
                </td>
                <td
                  v-if="
                    isEditing(i, j, section, item) &&
                    item.type != 'quality' &&
                    item.type != 'dealer' &&
                    item.type != 'department' &&
                    item.type != 'textarea'
                  "
                  class="content-td"
                >
                  <input
                    :type="isEditing(i, j, section, item) ? item.type || 'text' : 'text'"
                    class="text-right"
                    style="width: 100%"
                    :class="{
                      'no-button-style': !isEditing(i, j, section, item),
                      'hide-calendar': !isEditing(i, j, section, item),
                      'error-text': item.is_error
                    }"
                    :value="
                      isEditing(i, j, section, item)
                        ? form[item.key]
                        : (item.content ? item.content : form[item.key]) || '—'
                    "
                    :checked="form[item.key]"
                    @change="updateFormKey(item.key, $event)"
                    :disabled="!isEditing(i, j, section, item)"
                  />
                </td>
                <td v-if="isEditing(i, j, section, item) && item.type == 'quality'" class="text-right">
                  <select @change="updateFormKey(item.key, $event)" :value="form[item.key]">
                    <option value="">-</option>
                    <option value="0">Bad</option>
                    <option value="1">Average</option>
                    <option value="2">Good</option>
                  </select>
                </td>
                <td v-if="isEditing(i, j, section, item) && item.type == 'dealer'" class="text-right">
                  <select @change="updateFormKey(item.key, $event)" :value="form[item.key]">
                    <option v-for="dealer in getAvaiableDealers()" :value="dealer.url" v-bind:key="dealer.url">
                      {{ dealer.first_name }} {{ dealer.last_name }}
                    </option>
                  </select>
                </td>
                <td v-if="isEditing(i, j, section, item) && item.type == 'department'" class="text-right">
                  <select @change="updateFormKey(item.key, $event)" :value="form[item.key]">
                    <option value="">-</option>
                    <option
                      v-for="department in getAvaiableDepartments()"
                      :value="department.url"
                      v-bind:key="department.url"
                    >
                      {{ department.title }}
                    </option>
                  </select>
                </td>
              </tr>
            </table>
          </div>
          <div class="seperator margin-top-2 margin-bottom-2-05 grid-column-span-2"></div>
        </div>
        <div>
          <div>
            <div class="flex-row">
              <p class="h3-title vertical-flex-center scroll-margin-top" id="In App Comments">In App Comments</p>
              <secondary-button
                v-if="!editingInAppComments"
                class="margin-left-auto min-width-96"
                title="Edit"
                v-on:click="editingInAppComments = true"
              />
              <secondary-button
                v-if="editingInAppComments"
                class="margin-left-auto min-width-96"
                title="Save"
                v-on:click="saveForm()"
              />
            </div>
            <div class="margin-top-2-05">
              <p v-if="form.car_comments_date_time_updated" class="body-2-bold margin-bottom-05">
                Updated: {{ dateToDayMonthYearTimeReadableString(form.car_comments_date_time_updated) }}
              </p>
            </div>
            <textarea
              class="body-2"
              v-if="editingInAppComments"
              style="width: 100%; height: 200px; padding: 4px"
              :value="form.car_comments ? form.car_comments : 'No Comments'"
              @change="updateFormKey('car_comments', $event)"
            />
            <p class="body-2" v-if="!editingInAppComments">
              {{ form.car_comments ? form.car_comments : "No Comments" }}
            </p>
          </div>
          <div class="seperator margin-top-2 margin-bottom-2-05"></div>
          <div v-if="currentDealer" class="form-content wide">
            <div class="flex-row">
              <p class="title vertical-flex-center h3-title scroll-margin-top" id="Dealership Comments">
                Dealership Comments
              </p>
              <secondary-button
                v-if="!editingDealershipComments"
                class="margin-left-auto min-width-96"
                title="Edit"
                v-on:click="editingDealershipComments = true"
              />
              <secondary-button
                v-if="editingDealershipComments"
                class="margin-left-auto min-width-96"
                title="Save"
                v-on:click="saveForm()"
              />
            </div>

            <textarea
              class="body-2 margin-top-2-05"
              v-if="editingDealershipComments"
              style="width: 100%; height: 200px; padding: 4px"
              :value="form.dealership_comments ? form.dealership_comments : 'No Comments'"
              @change="updateFormKey('dealership_comments', $event)"
            />
            <p v-if="!editingDealershipComments" class="body-2 margin-top-2-05">
              {{ form.dealership_comments ? form.dealership_comments : "No Comments" }}
            </p>
            <div class="seperator margin-top-2 margin-bottom-2-05"></div>
          </div>
          <div class="form-content-container pagebreak">
            <div class="form-content">
              <div class="height-40 flex-row">
                <p class="h3-title vertical-flex-center scroll-margin-top" id="Damage Map">Damage Map</p>
              </div>
              <div v-if="form.damage_maps && form.damage_maps.length > 0">
                <damage-map :form="form" />
              </div>
              <div v-if="!(form.damage_maps && form.damage_maps.length > 0)">
                <p class="body-2 margin-top-2-05">No Damage Maps</p>
              </div>
            </div>
            <div class="form-content">
              <div class="flex-row">
                <p class="h3-title vertical-flex-center scroll-margin-top" id="Body Damage and Costs">
                  Body Damage and Costs
                </p>
                <secondary-button title="Add" class="flex-end margin-left-auto" v-on:click="addingBodyDamage = true" />
              </div>
              <p
                class="body-2 margin-top-2-05"
                v-if="!((form.body_damages && form.body_damages.length > 0) || evalDamageData(form).length > 0)"
              >
                No Body Damage or Costs
              </p>
              <table v-else class="results-table margin-top">
                <tr class="header">
                  <td>Description</td>
                  <td>Cost</td>
                  <td>Type</td>
                </tr>
                <tr
                  v-for="damage in form.body_damages"
                  v-bind:key="damage.url"
                  v-on:click="editingBodyDamage = damage"
                  class="selectable data-row"
                >
                  <td>{{ damage.description }}</td>
                  <td>${{ damage.cost }}</td>
                  <td>
                    {{ damage.is_mechanical ? "Mechanical" : "Body" }}
                  </td>
                </tr>
                <tr v-for="damage in evalDamageData(form)" v-bind:key="damage" class="data-row">
                  <td>{{ damage.title }}</td>
                  <td>{{ damage.content }}</td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="seperator margin-top-2 margin-bottom-2-05"></div>
          <div
            v-if="currentDealer && (currentDealer.is_manager || currentDealer.can_request_offers_even_if_sales)"
            class="form-content-container"
          >
            <div class="margin-right-1-025">
              <div class="flex-row margin-bottom-1-05">
                <p class="h3-title vertical-flex-center scroll-margin-top" id="Offers">Offers</p>
                <div class="position-relative margin-left-auto">
                  <secondary-button class="margin-right-05" title="Alerting..." v-if="alertingWinner" />
                  <secondary-button
                    v-if="!editingOfferDetails"
                    class="min-width-96"
                    title="Options"
                    :selected="showOfferOptionsDropdown"
                    v-on:click="
                      !showOfferOptionsDropdown
                        ? (() => {
                            closeAllDropdowns();
                            showOfferOptionsDropdown = true;
                          })()
                        : (() => {
                            closeAllDropdowns();
                          })()
                    "
                  />
                  <div v-if="showOfferOptionsDropdown" class="dropdown shadow body-2 padding-vertical-025">
                    <div
                      class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                      v-if="
                        currentDealer &&
                        (currentDealer.is_manager || currentDealer.can_request_offers_even_if_sales) &&
                        !form.is_lost &&
                        !form.winning_offer
                      "
                      v-on:click="
                        () => {
                          closeAllDropdowns();
                          showNewOfferModal();
                        }
                      "
                    >
                      Add
                    </div>
                    <div
                      class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                      v-if="form.winning_offer"
                      v-on:click="
                        () => {
                          closeAllDropdowns();
                          editingOfferDetails = true;
                        }
                      "
                    >
                      Edit
                    </div>
                    <div
                      class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                      v-if="form.winning_offer"
                      v-on:click="
                        () => {
                          closeAllDropdowns();
                          alertWinner();
                        }
                      "
                    >
                      Send Push Notification to Winner
                    </div>
                    <div
                      class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                      v-on:click="
                        () => {
                          closeAllDropdowns();
                          showTradePriceCalculator = true;
                        }
                      "
                    >
                      Trade Price Calculator
                    </div>
                    <div
                      class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
                      v-on:click="
                        () => {
                          closeAllDropdowns();
                          showRetailSalesArchiveModal = true;
                        }
                      "
                    >
                      Sales and Retail
                    </div>
                  </div>
                </div>
                <secondary-button
                  v-if="editingOfferDetails"
                  class="margin-left-auto min-width-96"
                  style="margin-right: 0px"
                  title="Save"
                  v-on:click="saveForm()"
                />
              </div>
              <table v-if="form.winning_offer" class="form-table">
                <tr>
                  <td class="heading-td">Intention of Trade</td>
                  <td class="content-td">
                    <input
                      v-if="!editingOfferDetails"
                      :value="formTradedPurpose()"
                      class="text-right"
                      :class="{
                        'no-button-style': !editingOfferDetails,
                        'hide-calendar': !editingOfferDetails
                      }"
                      :disabled="!editingOfferDetails"
                    />
                    <select
                      v-if="editingOfferDetails"
                      :value="form.traded_purpose"
                      class="body-2 padding-vertical-05 text-right dropdown-field"
                      @change="updateFormKey('traded_purpose', $event)"
                    >
                      <option value="0">Wholesale</option>
                      <option value="1">Retail</option>
                      <option value="2">Undecided</option>
                      <option value="3">Budget</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td class="heading-td">Estimated Time of Arrival</td>
                  <td class="content-td">
                    <input
                      type="date"
                      :value="form.traded_eta ? form.traded_eta : null"
                      class="text-right"
                      :class="{
                        'no-button-style': !editingOfferDetails,
                        'hide-calendar': !editingOfferDetails,
                        'dropdown-field': editingOfferDetails
                      }"
                      :disabled="!editingOfferDetails"
                      @change="updateFormKey('traded_eta', $event)"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="heading-td">Arrived</td>
                  <td class="content-td">
                    <input
                      :value="form.traded_arrived ? 'Yes' : 'No'"
                      class="text-right no-button-style"
                      :disabled="true"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="heading-td">
                    {{ currentDealer.is_manager ? "Appraisal value sent to sales staff" : "Appraisal Value" }}
                  </td>
                  <td class="content-td">
                    <input
                      :type="editingOfferDetails ? 'number' : 'text'"
                      class="text-right"
                      :class="{
                        'no-button-style': !editingOfferDetails,
                        'text-field': editingOfferDetails
                      }"
                      :value="editingOfferDetails ? form.sales_value : form.sales_value ? '$' + form.sales_value : '-'"
                      @change="updateFormKey('sales_value', $event)"
                      :disabled="!editingOfferDetails"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <autograb-box
              class="margin-left-1-025"
              :form="form"
              :auto_grab_fetched_data="form.auto_grab_fetched_data"
              v-if="currentDealer.is_manager"
              @newAutoGrabData="newAutoGrabData"
            />
          </div>
        </div>

        <div
          class="margin-top"
          v-if="
            (!currentDealer ||
              (currentDealer && (currentDealer.is_manager || currentDealer.can_request_offers_even_if_sales))) &&
            form.offers &&
            form.offers.length > 0
          "
        >
          <offer-table
            :form="form"
            @onFinal="markSelectedAsFinal"
            @onFinalAndArchive="markSelectedAsFinalAndArchive"
            @onUntraded="unmarkSelectedAsTraded"
            @onTradedWithPurpose="markSelectedAsTradedWithPurpose"
            @onAdjust="adjustOffer"
            @onShouldAdjustReserve="adjustReserve"
            @onHistory="showOfferHistory"
          />
        </div>
      </div>
      <new-offer-modal
        v-if="newOfferModalShowing"
        :existing-offers="form.offers"
        @submitCustom="addCustomOffer"
        @submitFavourite="addFavouriteOffer"
        @cancel="newOfferModalShowing = null"
      >
        <autograb-box
          :form="form"
          :auto_grab_fetched_data="form.auto_grab_fetched_data"
          :verticalHeading="true"
          v-if="currentDealer.is_manager"
          @newAutoGrabData="newAutoGrabData"
          class="margin-top"
        />
      </new-offer-modal>
      <adjust-offer-modal
        v-if="adjustingOffer"
        :offer="adjustingOffer"
        :showTraded="!form.winning_offer"
        @cancel="adjustingOffer = null"
        @onSave="sendOfferFromReseller"
      />
      <select-dealership-modal
        v-if="transferAppraisalModalShowing"
        @cancel="transferAppraisalModalShowing = false"
        @submit="transferForm"
      />
      <tender-list-modal v-if="addingToTender" @cancel="addingToTender = false" @submit="addToTender" />
      <body-damage-modal
        v-if="addingBodyDamage || editingBodyDamage"
        :editingBodyDamage="editingBodyDamage"
        @cancel="
          addingBodyDamage = false;
          editingBodyDamage = null;
        "
        @createBodyDamage="addBodyDamage"
      />
      <trade-price-calculator
        v-if="showTradePriceCalculator"
        :damage="form.body_damages"
        :form="form"
        @formUpdated="formUpdated"
        @cancel="showTradePriceCalculator = false"
      />
      <ready-for-trade-modal
        v-if="showReadyForTradeModal"
        @setReadyForTrade="setFormReadyForTrade"
        @cancel="showReadyForTradeModal = false"
      />
      <reasons-lost-modal
        v-if="showMarkLostModal"
        :current-lost-amount="form.lost_amount"
        @onSubmit="markLostWithReason"
        @cancel="showMarkLostModal = false"
      />
      <odometer-rollback-modal
        v-if="showOdometerRollbackModal"
        @cancel="showOdometerRollbackModal = false"
        :forms="form.odometer_rollbacks"
      />
      <online-sources-modal v-if="showOnlineSourcesModal" @cancel="showOnlineSourcesModal = false" :form="form" />
      <ppsr-form-modal
        v-model:form="form"
        @update:form="form = $event"
        :formId="form.id"
        v-if="ppsrModalVisible"
        @cancel="modalCancelled"
      />
      <offer-history-modal
        v-if="showOfferHistoryOffer"
        :offer="showOfferHistoryOffer"
        @cancel="showOfferHistoryOffer = null"
      />

      <StockCarForm :form="form" v-if="currentDealer" ref="stockCarForm" />
      <VendorStatement :form="form" v-if="currentDealer" ref="vendorStatement" />
      <RetailSalesArchiveModal
        v-if="showRetailSalesArchiveModal"
        :form="form"
        @cancel="showRetailSalesArchiveModal = false"
        @onSubmit="submitRetailSalesArchiveModal"
      />
      <AllDealerPacksModal v-if="showAllDealerPacksModal" @cancel="showAllDealerPacksModal = false" />
      <RegoStateModal
        v-if="showRegoStateModal"
        :current-car-registration-number="form.car_registration_number"
        :current-car-registration-state="form.car_registration_state"
        @cancel="showRegoStateModal = false"
        @submitted="recalibrateFormData"
      />
      <form-warning-modal v-if="showWarningModal" :form="form" @cancel="showWarningModal = false" />
      <upload-photo-modal
        v-if="showUploadPhotoModal"
        :form="form"
        @onPhoto="onPhotoAdded"
        @cancel="showUploadPhotoModal = false"
      />
      <OffsiteFormConversationModal
        v-if="showOffsiteConversation"
        :offsiteForm="form.offsite_conversation"
        @cancel="showOffsiteConversation = false"
      />
      <UpgradeInterestModal
        v-if="showUpgradeInterestModal"
        :upgradeInterest="form.upgrade_interest"
        @cancel="showUpgradeInterestModal = false"
      />
    </div>
  </div>
</template>

<script>
import {
  fetchForm,
  requestOffers,
  requestCustomOffer,
  markOfferAsFinal,
  markOfferAsTraded,
  unmarkOfferAsTraded,
  duplicateForm,
  markFormLost,
  unmarkFormLost,
  markFormArchived,
  markFormReadyForManagement,
  markFormFavourite,
  markFormArrived,
  transferForms,
  updateForm,
  updateFormWithDict,
  addFormToTender,
  removeFormFromTender,
  pushTuneLeadForForm,
  markFormReadyForTrade,
  alertWinner,
  markTrashed,
  checkOnlinePresence,
  recalibrateFormData,
  fetchFactoryVehicleOptions,
  gatherSellUpgradeInterest
} from "../../../api/Form.js";
import { createBodyDamage, updateBodyDamage } from "../../../api/Damage.js";
import { adjustOffer } from "../../../api/Offer.js";
import { endpoints } from "../../../api/axiosHandler.js";
import { mapGetters, mapState, mapActions } from "vuex";
import _ from "lodash";
import "viewerjs/dist/viewer.css";

import TertiaryButton from "../../../components/Buttons/v2/TertiaryButton.vue";
import StatusAlert from "../../../components/Views/v2/StatusAlert.vue";
import StatusIndicator from "../../../components/Views/v2/StatusIndicator.vue";
import SecondaryButton from "../../../components/Buttons/v2/SecondaryButton.vue";
import OfferTable from "../../../components/Views/v2/OfferTable/OfferTable.vue";
import AutograbBox from "../../../components/Views/v2/AutograbBox.vue";
import AdjustOfferModal from "../../../components/Modals/v2/AdjustOfferModal.vue";
import BodyDamageModal from "../../../components/Modals/v2/BodyDamageModal.vue";
import SelectDealershipModal from "../../../components/Modals/v2/TransferAppraisalModal.vue";
import ReasonsLostModal from "../../../components/Modals/v2/ReasonsLostModal.vue";
import TenderListModal from "../../../components/Modals/v2/TenderListModal.vue";
import OdometerRollbackModal from "../../../components/Modals/v2/OdometerRollbackModal.vue";
import OnlineSourcesModal from "../../../components/Modals/v2/OnlineSourcesModal.vue";
import RetailSalesArchiveModal from "../../../components/Modals/v2/RetailSalesArchiveModal.vue";
import NewOfferModal from "../../../components/Modals/v2/NewOfferModal.vue";
import PpsrFormModal from "../../../components/Modals/v2/PpsrFormModal.vue";
import StockCarForm from "../../../components/Views/v2/StockCarForm.vue";
import RegoStateModal from "../../../components/Modals/v2/RegoStateModal.vue";
import TradePriceCalculator from "../../../components/Modals/v2/TradePriceCalculator.vue";
import ReadyForTradeModal from "../../../components/Modals/v2/ReadyForTradeModal.vue";
import AllDealerPacksModal from "../../../components/Modals/v2/AllDealerPacksModal.vue";
import OfferHistoryModal from "../../../components/Modals/v2/OfferHistoryModal.vue";
import FormWarningModal from "../../../components/Modals/v2/FormWarningModal.vue";
import VendorStatement from "../../../components/Views/v2/VendorStatement.vue";
import UploadPhotoModal from "../../../components/Modals/v2/UploadPhotoModal.vue";
import DamageMap from "../../../components/Views/v2/Form/DamageMap.vue";
import OffsiteFormConversationModal from "../Offsites/Modals/OffsiteFormConversationModal.vue";
import UpgradeInterestModal from "./UpgradeInterestModal.vue";

import {
  dealershipData,
  customerInformationData,
  carInformationData,
  financialInformationData,
  ppsrInformationData,
  integrationInformationData,
  tradedPurposeOptions,
  tradedPurposeString,
  specObjectData,
  factoryVehicleOptionsData,
  evalDamageData,
  tenderHasStarted,
  tenderHasEnded,
  getWarningsAndDangers,
  pendingStageString
} from "../../../helpers/v2/formHelpers.js";
import { getTopValue } from "../../../helpers/v2/offerHelpers";
import { timeDifferenceString, dateToDayMonthYearTimeReadableString } from "../../../helpers/v2/utilityHelpers.js";

export default {
  components: {
    NewOfferModal,
    AdjustOfferModal,
    SelectDealershipModal,
    PpsrFormModal,
    TenderListModal,
    BodyDamageModal,
    ReadyForTradeModal,
    ReasonsLostModal,
    OdometerRollbackModal,
    OnlineSourcesModal,
    TradePriceCalculator,
    StockCarForm,
    VendorStatement,
    AutograbBox,
    DamageMap,
    AllDealerPacksModal,
    RetailSalesArchiveModal,
    OfferHistoryModal,
    RegoStateModal,
    FormWarningModal,
    UploadPhotoModal,
    OffsiteFormConversationModal,
    UpgradeInterestModal,

    SecondaryButton,
    TertiaryButton,
    StatusAlert,
    StatusIndicator,
    OfferTable
  },
  name: "Form",
  data: function () {
    return {
      previousAppraisal: null,
      nextAppraisal: null,
      form: null,
      showVehicleRating: false,
      newOfferModalShowing: false,
      transferAppraisalModalShowing: false,
      tradedPurposeModalShowing: false,
      tenderValue: null,
      showingImageViewer: false,
      editingIndex: null,
      editingDealershipComments: false,
      editingInAppComments: false,
      editingOfferDetails: false,
      ppsrModalVisible: false,
      addingToTender: false,
      adjustingOffer: false,
      showOfferHistoryOffer: null,
      addingBodyDamage: false,
      editingBodyDamage: null,
      showTradePriceCalculator: false,
      showReadyForTradeModal: false,
      showMarkLostModal: false,
      showUploadPhotoModal: false,
      showOdometerRollbackModal: false,
      showOnlineSourcesModal: false,
      alertingWinner: false,
      checkingOnlinePresence: false,
      fetchingFetchFactoryVehicleOptions: false,
      showAllDealerPacksModal: false,
      showOffsiteConversation: false,

      showChangeStatusDropdown: false,
      showGenerateReportsDropdown: false,
      showMoreOptionsDropdown: false,
      showOfferOptionsDropdown: false,
      showRetailSalesArchiveModal: false,
      showRegoStateModal: false,
      showWarningModal: false,
      showUpgradeInterestModal: false
    };
  },
  methods: {
    statusButtons: function () {
      var toReturn = [];

      if (this.currentDealer) {
        toReturn.push({
          title: "Send back to Pending",
          action: () => {
            this.sendBackToPending();
          }
        });

        if (this.currentDealer.is_manager) {
          if (this.form.is_trash) {
            toReturn.push({
              title: "Restore From Trash",
              action: () => {
                this.markTrashed();
              }
            });
          } else {
            toReturn.push({
              title: "Trash Appraisal",
              action: () => {
                this.markTrashed();
              }
            });
          }

          if (this.form.final_offer) {
            toReturn.push({
              title: "Mark as Traded",
              action: () => {
                this.closeAllDropdowns();
                this.tradedPurposeModalShowing = true;
              }
            });
          }
        }

        if (this.currentDealer.is_manager && !this.form.is_archived) {
          toReturn.push({
            title: "Archive (DMS)",
            action: () => {
              this.markArchived(true);
            }
          });
        } else {
          toReturn.push({
            title: "Unarchive",
            action: () => {
              this.markArchived(false);
            }
          });
        }

        if (!this.currentDealer.is_manager && !this.form.is_ready_for_management) {
          toReturn.push({
            title: "Ready for Management",
            action: () => {
              this.markReadyForManagement();
            }
          });
        }

        if (!this.currentDealer.is_manager && !this.form.is_ready_for_traded && !this.form.winning_offer) {
          toReturn.push({
            title: "Ready For Trade",
            action: () => {
              this.showReadyForTradeModal = true;
            }
          });
        }

        if (this.form.is_lost) {
          toReturn.push({
            title: "Unmark Lost",
            action: () => {
              this.unMarkLost();
            }
          });
        } else {
          toReturn.push({
            title: "Mark Lost",
            action: () => {
              this.markLost();
            }
          });
        }

        if (!this.form.is_lost && this.form.traded_purpose != null && !this.form.traded_arrived) {
          toReturn.push({
            title: "Arrived",
            action: () => {
              this.markArrived();
            }
          });
        }
      }

      return toReturn;
    },
    reportButtons: function () {
      var toReturn = [];

      toReturn.push({
        title: "Print",
        action: () => {
          this.printPage();
        }
      });

      toReturn.push({
        title: "Appraisal PDF",
        action: () => {
          this.goToPDF();
        }
      });

      toReturn.push({
        title: "Extended PDF",
        action: () => {
          this.goToFleetPDF();
        }
      });

      if (this.currentDealer) {
        toReturn.push({
          title: "PPSR",
          action: () => {
            this.showPPSRModal();
          }
        });

        toReturn.push({
          title: "Stock Car Form",
          action: () => {
            this.closeAllDropdowns();
            this.$refs.stockCarForm.download();
          }
        });

        toReturn.push({
          title: "Vendor Statement",
          action: () => {
            this.closeAllDropdowns();
            this.$refs.vendorStatement.download();
          }
        });

        toReturn.push({
          title: "Dealer Packs",
          action: () => {
            this.closeAllDropdowns();
            this.showAllDealerPacksModal = true;
          }
        });
      }

      return toReturn;
    },
    moreButtons: function () {
      var toReturn = [];

      if (this.currentDealer) {
        if (this.showVehicleRating) {
          toReturn.push({
            title: "Hide Vehicle Rating",
            action: () => {
              this.closeAllDropdowns();
              this.showVehicleRating = !this.showVehicleRating;
            }
          });
        } else {
          toReturn.push({
            title: "Show Vehicle Rating",
            action: () => {
              this.closeAllDropdowns();
              this.showVehicleRating = !this.showVehicleRating;
            }
          });
        }

        if (this.currentDealer.is_manager) {
          toReturn.push({
            title: "Trade Price Calculator",
            action: () => {
              this.closeAllDropdowns();
              this.showTradePriceCalculator = true;
            }
          });
          if (this.form.is_favourite) {
            toReturn.push({
              title: "Unmark as Favourite",
              action: () => {
                this.closeAllDropdowns();
                this.markFavourite(false);
              }
            });
          } else {
            toReturn.push({
              title: "Mark as Favourite",
              action: () => {
                this.closeAllDropdowns();
                this.markFavourite(true);
              }
            });
          }

          if (this.form.car_model_code && this.form.car_model_year) {
            toReturn.push({
              title: "Your Market",
              action: () => {
                this.closeAllDropdowns();
                this.goToModelCodeSearch();
              }
            });
          }

          if (this.currentDealership.has_revo_lead_access) {
            toReturn.push({
              title: "Push Tune Lead",
              action: () => {
                this.closeAllDropdowns();
                this.pushTuneLead();
              }
            });
          }

          toReturn.push({
            title: "Recalibrate Appraisal by Registration",
            action: () => {
              this.closeAllDropdowns();
              this.showRegoStateModal = true;
            }
          });
        }

        if (this.currentDealer.is_super_manager) {
          toReturn.push({
            title: "Transfer Appraisal",
            action: () => {
              this.closeAllDropdowns();
              this.transferAppraisalModalShowing = true;
            }
          });
        }

        if (
          this.currentDealer &&
          (this.currentDealer.has_autograb_access || this.currentDealership.is_autograb_account)
        ) {
          if (this.form.auto_grab_listing_history == null) {
            toReturn.push({
              title: "Check Online Presence",
              action: () => {
                this.closeAllDropdowns();
                this.fetchOnlinePresence();
              }
            });
          }
        }

        if (this.currentDealership.is_tender_account) {
          toReturn.push({
            title: "Adjust Reserve",
            action: () => {
              this.closeAllDropdowns();
              this.adjustReserve();
            }
          });
        }

        if (
          this.currentDealer.is_manager &&
          this.currentDealership.is_tender_account &&
          !this.form.tender_start_time &&
          this.form.dealership.url == this.currentDealership.url
        ) {
          toReturn.push({
            title: "Add To Tender",
            action: () => {
              this.addingToTender = true;
            }
          });
        }

        if (this.canRemoveFromTender()) {
          toReturn.push({
            title: "Remove From Tender",
            action: () => {
              this.removeFromTender();
            }
          });
        }

        toReturn.push({
          title: "Add Photos",
          action: () => {
            this.closeAllDropdowns();
            this.showUploadPhotoModal = true;
          }
        });

        if (this.form.offsite_conversation) {
          toReturn.push({
            title: "View Offsite Conversation",
            action: () => {
              this.closeAllDropdowns();
              this.showOffsiteConversation = true;
            }
          });
        }

        if (this.form.upgrade_interest) {
          toReturn.push({
            title: "View Upgrade Interest Conversation",
            action: () => {
              this.closeAllDropdowns();
              this.showUpgradeInterestModal = true;
            }
          });
        }

        if (this.currentDealership.has_offsite_upgrade_access && !this.form.upgrade_interest) {
          toReturn.push({
            title: "Gather Sell or Upgrade Interest",
            action: () => {
              this.closeAllDropdowns();
              this.gatherSellUpgradeInterest();
            }
          });
        }
      }

      return toReturn;
    },
    tradedPurposeOptions: function () {
      return tradedPurposeOptions;
    },
    closeAllDropdowns: function () {
      this.showChangeStatusDropdown = false;
      this.showGenerateReportsDropdown = false;
      this.showMoreOptionsDropdown = false;
      this.showOfferOptionsDropdown = false;
      this.tradedPurposeModalShowing = false;
    },
    showPPSRModal: function () {
      this.closeAllDropdowns();
      this.ppsrModalVisible = true;
    },
    modalCancelled: function () {
      this.ppsrModalVisible = false;
    },
    scrollToId: function (id) {
      document.getElementById(id).scrollIntoView();
    },
    loadData: function () {
      this.previousAppraisal = this.getPreviousForm(this.$route.params.id);
      this.nextAppraisal = this.getNextForm(this.$route.params.id);
      this.form = this.getForm(this.$route.params.id);
      fetchForm(this.$route.params.id)
        .then(form => {
          this.form = form;
          this.setTenderValue();
          window.scrollTo(0, 0);
        })
        .catch(error => {
          this.addError(error);
        });
    },
    formUpdated: function (form) {
      this.form = form;
    },
    fullBodyPhoto: function () {
      if (this.form.general_photos) {
        let fbp = this.form.general_photos.filter(gp => {
          return gp.is_full_body;
        })[0];
        let nonVin = this.form.general_photos.filter(gp => {
          return !gp.is_vin;
        })[0];
        if (fbp) {
          return fbp;
        } else {
          if (nonVin) {
            return nonVin;
          }
        }
        return this.form.general_photos[0];
      }
    },
    otherPhotos: function () {
      return this.form.general_photos.filter(gp => {
        return !gp.is_full_body;
      });
    },
    offerForLoggedInReseller() {
      if (this.currentReseller && this.form && this.form.offers) {
        return this.form.offers.filter(offer => {
          return offer.reseller ? offer.reseller.url == this.currentReseller.url : false;
        })[0];
      }
      return null;
    },
    pendingStageString: function (form) {
      return pendingStageString(form);
    },
    evalDamageData: function (form) {
      return evalDamageData(form);
    },
    formData: function () {
      return [
        dealershipData(this.form),
        customerInformationData(),
        carInformationData(this.form),
        financialInformationData(this.form),
        ppsrInformationData(this.form),
        integrationInformationData(this.form),
        factoryVehicleOptionsData(this.form),
        specObjectData(this.form)
      ].filter(dict => {
        return dict.data.length > 0;
      });
    },
    formDataBoxTitles: function () {
      var toReturn = this.formData().map(section => {
        return section.title;
      });
      toReturn.push("In App Comments");
      if (this.currentDealer) {
        toReturn.push("Dealership Comments");
      }
      toReturn.push("Damage Map");
      toReturn.push("Body Damage and Costs");
      if (this.currentDealer && this.currentDealer.is_manager) {
        toReturn.push("Offers");
      }
      return toReturn;
    },
    formDataForIndex: function (i) {
      let section = this.formData()[i];
      if (section) {
        if (section.wide) {
          let section1 = _.cloneDeep(section);
          let section2 = _.cloneDeep(section);
          let data = section.data.filter(item => !item.isHidden);
          var half_length = Math.ceil(data.length / 2);
          section1.data = section1.data.filter(item => !item.isHidden).splice(0, half_length);
          section2.data = section2.data.filter(item => !item.isHidden).splice(half_length, section.data.length);
          section2.title = null;

          return [section1, section2];
        } else {
          let nextData = this.formData()[i + 1];
          if (nextData) {
            if (!nextData.wide) {
              return [this.formData()[i], nextData];
            } else {
              return [this.formData()[i]];
            }
          } else {
            return [this.formData()[i]];
          }
        }
      }
    },
    calculateIndexForFormData: function () {
      var indeces = [];
      for (var i = 0; i < this.formData().length; i++) {
        indeces.push(i);
        if (!this.formData()[i].wide) {
          if (this.formData()[i + 1] && !this.formData()[i + 1].wide) {
            i++;
          }
        }
      }
      return indeces;
    },
    autograbIsOnTheInternet: function () {
      return Object.keys(this.form.auto_grab_listing_history.online_sources).length > 0;
    },
    fetchOnlinePresence: function () {
      this.checkingOnlinePresence = true;
      checkOnlinePresence(this.form)
        .then(response => {
          this.checkingOnlinePresence = false;
          this.form.auto_grab_listing_history = response;
        })
        .catch(error => {
          this.checkingOnlinePresence = false;
          this.addError(error);
        });
    },
    fetchFactoryVehicleOptions: function () {
      if (this.fetchingFetchFactoryVehicleOptions) {
        return;
      }
      this.fetchingFetchFactoryVehicleOptions = true;
      fetchFactoryVehicleOptions(this.form)
        .then(response => {
          this.fetchingFetchFactoryVehicleOptions = false;
          this.form.auto_grab_factory_vehicle_options = response.factory_vehicle_options;
          this.form.auto_grab_vehicle_feature_options = response.features;
        })
        .catch(error => {
          this.fetchingFetchFactoryVehicleOptions = false;
          this.addError(error);
        });
    },
    isEditing: function (i, j, section, item) {
      return (
        this.editingIndex &&
        item.key &&
        (section.wide ? this.editingIndex[0] == i : this.editingIndex == [i, j].toString())
      );
    },
    updateFormKey: function (key, event) {
      let value = event.target.type == "checkbox" ? event.target.checked : event.target.value;
      this.form[key] = value;
    },
    saveForm: function () {
      this.editingIndex = null;
      this.editingDealershipComments = false;
      this.editingOfferDetails = false;
      this.editingInAppComments = false;
      updateForm(this.form)
        .then(response => {
          this.form = response;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    onPhotoAdded: function (photo) {
      this.form.general_photos.unshift(photo);
    },
    backToSearch: function () {
      this.$router.push({ name: "Search" });
    },
    goToNextAppraisal: function () {
      this.$router.push({
        name: "Form",
        params: { id: this.nextAppraisal.id }
      });
    },
    goToPreviousAppraisal: function () {
      this.$router.push({
        name: "Form",
        params: { id: this.previousAppraisal.id }
      });
    },
    goToModelCodeSearch: function () {
      let route = this.$router.resolve({
        name: "ModelCode",
        params: { mc: this.form.car_model_code, year: this.form.car_model_year }
      });
      window.open(route.href, "_blank");
    },
    formTradedPurpose: function () {
      if (this.form == null) {
        return "-";
      }
      return tradedPurposeString(this.form.traded_purpose);
    },
    showNewOfferModal: function () {
      if (this.form.car_mileage) {
        this.newOfferModalShowing = true;
      } else {
        alert("Please fill in the Odometer section in Car Information before requesting offers.");
      }
    },
    printPage: function () {
      this.closeAllDropdowns();
      this.$nextTick(() => {
        print();
      });
    },
    getTopValue: function (offer) {
      return getTopValue(offer, this.currentDealer);
    },
    adjustReserve: function () {
      var newReserve = prompt("Type in the reserve price", this.form.reserve_price);
      if (newReserve && parseInt(newReserve)) {
        this.form["reserve_price"] = parseInt(newReserve);
        this.saveForm();
      } else {
        alert("Reserve has to be a number (i.e: put in 10000 for a reserve of $10,000).");
      }
    },
    newAutoGrabData: function (auto_grab_fetched_data) {
      this.form.auto_grab_fetched_data = auto_grab_fetched_data;
    },
    sendBackToPending: function () {
      let alert = confirm("Are you sure you want to duplicate this appraisal?");
      if (alert == true) {
        let confirmTrash = confirm("Do you also want to mark this appraisal as trashed?");
        duplicateForm(this.form)
          .then(response => {
            if (confirmTrash) {
              markTrashed(this.form, true)
                .then(() => {
                  this.$router.push({
                    name: "Form",
                    params: { id: response.id }
                  });
                  this.closeAllDropdowns();
                })
                .catch(error => {
                  this.addError(error);
                });
            } else {
              this.$router.push({
                name: "Form",
                params: { id: response.id }
              });
              this.closeAllDropdowns();
            }
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    pushTuneLead: function () {
      var confirmation = confirm("Are you sure you want to push this appraisal to Tune as a Lead?");
      if (confirmation) {
        pushTuneLeadForForm(this.form)
          .then(() => {
            alert("Tune Lead pushed successfully.");
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    setFormReadyForTrade: function (eta) {
      markFormReadyForTrade(this.form, eta)
        .then(() => {
          this.form.is_ready_for_traded = true;
          this.showReadyForTradeModal = false;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    recalibrateFormData: function (recalibatinDict) {
      recalibrateFormData(this.form, recalibatinDict.car_registration_number, recalibatinDict.car_registration_state)
        .then(response => {
          this.showRegoStateModal = false;
          this.form = response;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    markLost: function () {
      this.showMarkLostModal = true;
    },
    markLostWithReason(lostReasonDict) {
      markFormLost(this.form, lostReasonDict.lostReason, lostReasonDict.lostAmount ? lostReasonDict.lostAmount : null)
        .then(() => {
          this.showMarkLostModal = false;
          this.form.is_lost = true;
          this.form.reasons_lost = lostReasonDict.lostReason;
          this.form.lost_amount = lostReasonDict.lostAmount;
          this.closeAllDropdowns();
        })
        .catch(error => {
          this.showMarkLostModal = false;
          this.addError(error);
        });
    },
    unMarkLost: function () {
      var confirmation = confirm("Are you sure you want to unset this appraisal as lost?");
      if (confirmation) {
        unmarkFormLost(this.form)
          .then(() => {
            this.form.is_lost = false;
            this.form.lost_amount = null;
            this.closeAllDropdowns();
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    markArchived: function (archived) {
      var confirmation = confirm(
        archived
          ? "Are you sure you want to set this appraisal as archived?"
          : "Are you sure you want to unset this appraisal as archived?"
      );
      if (confirmation) {
        markFormArchived(this.form, archived)
          .then(response => {
            this.form = response;
            this.closeAllDropdowns();
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    markReadyForManagement: function () {
      if (!this.form.car_mileage) {
        alert(
          "Please fill in the Odometer section in Car Information before marking this appraisal as ready for management."
        );
        return;
      }
      var confirmation = confirm("Are you sure you want to set this appraisal as ready for management?");
      if (confirmation) {
        markFormReadyForManagement(this.form)
          .then(response => {
            this.form = response;
            this.closeAllDropdowns();
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    markFavourite: function (favourite) {
      var confirmation = confirm(
        favourite
          ? "Are you sure you want to set this appraisal as a favourite?"
          : "Are you sure you want to unset this appraisal as a favourite?"
      );
      if (confirmation) {
        markFormFavourite(this.form, favourite)
          .then(response => {
            this.form = response;
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    markArrived: function () {
      var confirmation = confirm("Are you sure you want to set this appraisal as arrived?");
      if (confirmation) {
        markFormArrived(this.form)
          .then(response => {
            alert(response.detail);
            this.form.traded_arrived = true;
            this.form.traded_eta = new Date().toLocaleDateString();
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    markTrashed: function () {
      if (this.form.is_trash) {
        var confirmation = confirm("Are you sure you want to restore this appraisal?");
        if (confirmation) {
          markTrashed(this.form, false)
            .then(response => {
              this.form = response;
              this.closeAllDropdowns();
            })
            .catch(error => {
              this.addError(error);
            });
        }
      } else {
        var confirmation1 = confirm(
          "Are you sure you want to trash this appraisal? Wholesalers will still be able to see it if you have sent it to them, but it will no longer appear in your appraisals list."
        );
        if (confirmation1) {
          markTrashed(this.form, true)
            .then(response => {
              this.form = response;
              this.closeAllDropdowns();
            })
            .catch(error => {
              this.addError(error);
            });
        }
      }
    },
    transferForm: function (dealershipID) {
      transferForms([this.form.url], endpoints.dealership + dealershipID + "/")
        .then(response => {
          this.addForms(response);
          this.transferAppraisalModalShowing = false;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    addToTender: function (tenderID) {
      addFormToTender(this.form, tenderID)
        .then(response => {
          this.form = response;
          this.setTenderValue();
          this.addingToTender = false;
          this.closeAllDropdowns();
        })
        .catch(error => {
          this.addError(error);
        });
    },
    canRemoveFromTender: function () {
      if (this.form.tender_start_time && this.form.tender_end_time) {
        if (
          this.currentDealer &&
          this.currentDealer.is_manager &&
          this.currentDealership.is_tender_account &&
          this.form.dealership.url == this.currentDealership.url
        ) {
          let startDate = new Date(this.form.tender_start_time);
          if (startDate && startDate.getTime() > new Date().getTime()) {
            return true;
          }
        }
      }

      return false;
    },
    removeFromTender: function () {
      var confirmation = confirm("Are you sure you want to remove this appraisal from its tender?");
      if (confirmation) {
        removeFormFromTender(this.form)
          .then(response => {
            this.form = response;
            this.setTenderValue();
            this.closeAllDropdowns();
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    addCustomOffer: function (customDict) {
      requestCustomOffer({
        custom_name: customDict.custom_name,
        is_super_manager_only: customDict.is_super_manager_only,
        price: customDict.price,
        form: this.form.url,
        dealer: endpoints.dealer + this.currentDealer.id + "/",
        traded: customDict.traded
      })
        .then(response => {
          this.newOfferModalShowing = false;
          this.handleTradedThenArchive(response, customDict.final, customDict.traded, customDict.archive);
        })
        .catch(error => {
          this.addError(error);
        });
    },
    unmarkAllOffersAsFinal: function () {
      this.form.offers.forEach(offer => {
        offer.is_final = false;
      });
    },
    addFavouriteOffer: function (resellers) {
      requestOffers(this.form.id, { reseller: resellers })
        .then(response => {
          response.forEach(offer => {
            this.form.offers.push(offer);
          });
          this.form.dealer = this.currentDealer;
        })
        .catch(error => {
          this.addError(error);
        });
      this.newOfferModalShowing = false;
    },
    submitRetailSalesArchiveModal: function (dict) {
      updateFormWithDict(this.form, dict)
        .then(response => {
          this.showRetailSalesArchiveModal = false;
          this.form = response;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    markSelectedAsFinal: function (offer) {
      this.handleMarkSelectedAsFinal(offer, false);
    },
    markSelectedAsFinalAndArchive: function (offer) {
      this.handleMarkSelectedAsFinal(offer, true);
    },
    handleMarkSelectedAsFinal: function (offer, markFinal) {
      if (this.form.final_offer && this.form.final_offer.url == offer.url) {
        unmarkOfferAsTraded(this.form)
          .then(response => {
            this.form = response;
          })
          .catch(error => {
            this.addError(error);
          });
      } else {
        var proceed = true;
        if (this.form.final_offer) {
          let alert = confirm("There is already an offer marked final. Are you sure you want to override it?");
          proceed = alert;
        }
        if (proceed) {
          markOfferAsFinal(offer.url)
            .then(response => {
              this.unmarkAllOffersAsFinal();
              this.form.offers = this.form.offers.filter(offer => {
                return offer.url != response.url;
              });
              this.form.offers.unshift(response);
              this.form.final_offer = response;
              if (markFinal) {
                this.handleMarkArchived();
              }
            })
            .catch(error => {
              this.addError(error);
            });
        }
      }
    },
    unmarkSelectedAsTraded: function (offer) {
      if (this.form.winning_offer && this.form.winning_offer.url == offer.url) {
        unmarkOfferAsTraded(this.form)
          .then(response => {
            this.form = response;
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    markFinalAsTradedWithPurpose: function (tradedPurpose) {
      this.markSelectedAsTradedWithPurpose({ offer: this.form.final_offer, purpose: tradedPurpose });
      this.tradedPurposeModalShowing = false;
    },
    markSelectedAsTradedWithPurpose: function (emitDict) {
      markOfferAsTraded(emitDict.offer.url, emitDict.purpose)
        .then(response => {
          this.unmarkAllOffersAsFinal();
          this.form.offers = this.form.offers.filter(offer => {
            return offer.url != response.url;
          });
          this.form.offers.unshift(response);
          this.form.final_offer = response;
          this.form.winning_offer = response;
          this.form.traded_purpose = emitDict.purpose;
          this.editingOfferDetails = true;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    adjustOffer: function (offer) {
      this.adjustingOffer = offer;
    },
    showOfferHistory: function (offer) {
      this.showOfferHistoryOffer = offer;
    },
    alertWinner: function () {
      if (this.alertingWinner) {
        return;
      }
      this.alertingWinner = true;
      alertWinner(this.form)
        .then(() => {
          this.alertingWinner = false;
        })
        .catch(error => {
          this.addError(error);
          this.alertingWinner = false;
        });
    },
    setTenderValue: function () {
      if (this.form.tender_start_time) {
        if (tenderHasEnded(this.form.tender_end_time)) {
          this.tenderValue = "Tender Has Ended.";
        } else if (tenderHasStarted(this.form.tender_start_time)) {
          setTimeout(() => {
            if (
              !this.editingIndex &&
              !this.editingDealershipComments &&
              !this.editingInAppComments &&
              !this.showingImageViewer
            ) {
              this.tenderValue =
                "Tender Ending: " + timeDifferenceString(new Date(this.form.tender_end_time), new Date());
            }
          }, 1000);
        } else {
          setTimeout(() => {
            if (!this.editingIndex && !this.editingDealershipComments && !this.editingInAppComments) {
              this.tenderValue =
                "Tender Starting: " + timeDifferenceString(new Date(this.form.tender_start_time), new Date());
            }
          }, 1000);
        }
      } else {
        this.tenderValue = null;
      }
    },
    sendOfferFromReseller: function (offerDict) {
      adjustOffer(this.adjustingOffer, offerDict)
        .then(response => {
          if (this.currentDealer) {
            this.form.offers = this.form.offers.filter(offer => offer.url != this.adjustingOffer.url);
            this.handleTradedThenArchive(response, offerDict.final, offerDict.traded, offerDict.archive);
          } else {
            this.form.offers = [response];
          }
          if (this.form.final_offer && this.form.final_offer.url == this.adjustingOffer.url) {
            response.is_final = true;
            this.form.final_offer = response;
          }
          if (this.form.winning_offer && this.form.winning_offer.url == this.adjustingOffer.url) {
            this.form.winning_offer = response;
          }

          this.adjustingOffer = null;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    gatherSellUpgradeInterest: function () {
      gatherSellUpgradeInterest(this.form)
        .then(response => {
          this.form.upgrade_interest = response;
          alert("Sell or Upgrade Interest text message sent!");
        })
        .catch(error => {
          this.addError(error);
        });
    },
    getWarningsAndDangers: function () {
      if (!this.form) {
        return;
      }
      return (
        getWarningsAndDangers(this.form).dangers.length > 0 || getWarningsAndDangers(this.form).warnings.length > 0
      );
    },
    ...mapActions({
      addForms: "formStore/addForms",
      addError: "errorStore/addError"
    }),
    goToPDF: function () {
      this.closeAllDropdowns();
      let routeData = this.$router.resolve({
        name: "FormPDF",
        params: { id: this.form.id }
      });
      window.open(routeData.href, "_blank");
    },
    goToFleetPDF: function () {
      this.closeAllDropdowns();
      let routeData = this.$router.resolve({
        name: "FleetPDF",
        params: { id: this.form.id }
      });
      window.open(routeData.href, "_blank");
    },
    addBodyDamage: function (dict) {
      if (dict.url) {
        updateBodyDamage(dict.url, dict)
          .then(response => {
            this.form.body_damages = this.form.body_damages.filter(bodyDamage => {
              return bodyDamage.url != dict.url;
            });
            this.form.body_damages.push(response);
            this.addingBodyDamage = false;
            this.editingBodyDamage = null;
          })
          .catch(error => {
            this.addError(error);
          });
      } else {
        createBodyDamage(this.form.url, dict)
          .then(response => {
            this.form.body_damages.push(response);
            this.addingBodyDamage = false;
            this.editingBodyDamage = null;
          })
          .catch(error => {
            this.addError(error);
          });
      }
    },
    dateToDayMonthYearTimeReadableString(dateString) {
      return dateToDayMonthYearTimeReadableString(dateString);
    },
    handleMarkArchived: function () {
      markFormArchived(this.form, true)
        .then(response => {
          this.form = response;
        })
        .catch(error => {
          this.addError(error);
        });
    },
    handleTradedThenArchive(offer, final, traded, archive) {
      if (traded) {
        markOfferAsTraded(offer.url, null)
          .then(response => {
            this.unmarkAllOffersAsFinal();
            this.form.offers.unshift(response);
            this.form.final_offer = response;
            this.form.winning_offer = response;
            this.editingOfferDetails = true;
            if (archive) {
              this.handleMarkArchived();
            }
          })
          .catch(error => {
            this.addError(error);
          });
      } else if (final) {
        markOfferAsFinal(offer.url)
          .then(response => {
            this.unmarkAllOffersAsFinal();
            this.form.offers.unshift(response);
            this.form.final_offer = response;
            if (archive) {
              this.handleMarkArchived();
            }
          })
          .catch(error => {
            this.addError(error);
          });
      } else if (archive) {
        this.form.offers.push(offer);
        this.handleMarkArchived();
      } else {
        this.form.offers.push(offer);
      }
    },
    getAvaiableDealers: function () {
      return this.dealershipWithUrl(this.form.dealership.url).dealers.filter(dealer => dealer.is_active);
    },
    getAvaiableDepartments: function () {
      return this.dealershipWithUrl(this.form.dealership.url).departments;
    }
  },
  computed: {
    ...mapGetters({
      getForm: "formStore/getForm",
      getNextForm: "formStore/getNextForm",
      dealershipWithUrl: "dealershipStore/dealershipWithUrl",
      getPreviousForm: "formStore/getPreviousForm"
    }),
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer,
      currentDealership: state => state.dealershipStore.currentDealership,
      currentReseller: state => state.resellerStore.currentReseller
    })
  },
  watch: {
    $route: function () {
      if (this.$route.name == "Form") {
        this.loadData();
      }
    },
    tenderValue: function () {
      this.setTenderValue();
    },
    editingIndex: function (newValue) {
      if (!newValue) {
        this.setTenderValue();
      }
    },
    editingDealershipComments: function (newValue) {
      if (!newValue) {
        this.setTenderValue();
      }
    },
    editingInAppComments: function (newValue) {
      if (!newValue) {
        this.setTenderValue();
      }
    },
    showingImageViewer: function (newValue) {
      if (!newValue) {
        this.setTenderValue();
      }
    }
  },
  mounted: function () {
    const app = this;
    this.$nextTick(() => {
      app.loadData();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#form-page {
  padding-top: $PADDING_X2_05;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.highlight-on-hover:hover {
  background-color: $BACKGROUND_HOVER_COLOR;
}

.full-photo {
  height: 158px;
  width: 158px;
}

.other-photo-container {
  overflow: scroll;
  max-height: 158px;
}

.other-photo {
  width: 50px;
  height: 50px;
  margin-left: $PADDING_X025;
  margin-bottom: $PADDING_X025;
}

.more-photo-indicator {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 25px;
  width: 50px;
  background-color: rgba($color: black, $alpha: 0.7);
}

.more-photo-img {
  height: 6px;
  margin-top: 4px;
}

.more-photo-text {
  @extend .body-tiny;

  color: #ffffff;
}

.form-content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .form-content:nth-child(1) {
    margin-right: 20px;
  }

  .form-content:nth-child(2) {
    margin-left: 20px;
  }
}

.seperator {
  height: 8px;
  background-color: $CARD_BACKGROUND_COLOR;
}

.grid-column-span-2 {
  grid-column: span 2;
}

.error-text {
  font-weight: bold;
  color: red;
}

.floating {
  position: fixed;
  top: 50%;
  left: 16px;
}

.show-on-hover {
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
}

.scroll-margin-top {
  scroll-margin-top: 180px;
}
</style>
